<template>
    <div class="other_main">
        <div class="organization">
            <span>{{$t("设置")}}</span>
            <!-- <div class="organization_back_del">取消</div> -->
            <div class="user_right_btn2" @click="sure" v-loading="btn_loading">{{$t("保存")}}</div>
        </div>
        <div class="organization_line"></div>
        <div class="user_detail">
            <div class="m16">
                <div class="user_detail_icon"></div>
                <div class="user_detail_label">{{$t("头像")}}</div>
                <el-upload class="user_detail_upload" action="#" :on-exceed="onExceed" :before-upload="beforeAvatarUpload"
                    :http-request="upload" list-type="picture-card" :on-preview="handlePreview" :limit="1"
                    :on-remove="handleRemove" :file-list="fileList">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>
            <div class="user_detail_flex">
                <div class="user_detail_flex_1">
                    <div class="user_detail_icon"></div>
                    <div class="user_detail_label">{{$t("公司名称")}}</div>
                    <el-input placeholder="" v-model="from.cmcname" :disabled="true" class="other_main_dig_flex_input">
                    </el-input>
                </div>
                <div class="user_detail_flex_1">
                    <div class="user_detail_icon"></div>
                    <div class="user_detail_label">{{$t("手机号")}}</div>
                    <el-input placeholder="" v-model="from.tel" class="other_main_dig_flex_input">
                    </el-input>
                </div>
                <div class="user_detail_flex_1" v-if="value">
                    <div class="user_detail_label">{{$t("社媒账号")}}</div>
                    <el-input placeholder="" v-model="from.account" class="other_main_dig_flex_input">
                        <template slot="append">
                            <div>
                                <el-dropdown trigger="click">
                                    <div style="cursor: pointer;">
                                        <el-image
                                                style="width: 20px; height: 20px;cursor: pointer;position: relative;top:3px;"
                                                :src="$store.state.host + '/entrance' + value"></el-image>
                                        <i class="el-icon-arrow-down el-icon--right"
                                            style="position: relative;top: -3px;"></i>
                                    </div>
                                    <el-dropdown-menu slot="dropdown" class="user_drop2">
                                        <el-dropdown-item v-for="item in accountList" :key="item.sid">
                                            <el-image
                                                style="width: 20px; height: 20px;cursor: pointer;position: relative;top: 5px;"
                                                :src="$store.state.host + '/entrance' + item.image" @click="value = item.image"></el-image>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </template>
                    </el-input>
                </div>
            </div>
            <div class="mt90">
                <div class="mt90_edit" @click="openPwd">{{$t("修改密码")}}</div>
                <div class="mt90_del" @click="exit">{{$t("退出登录")}}</div>
            </div>
        </div>


        <el-dialog :title="$t('修改密码')" :visible.sync="dialogVisible" width="480px">
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    <span class="red">*</span>
                    {{$t("旧密码")}}
                </div>
                <el-input placeholder="" show-password v-model="from2.old" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    <span class="red">*</span>
                    {{$t("新密码")}}
                </div>
                <el-input placeholder="" show-password v-model="from2.new" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    {{$t("确认密码")}}
                </div>
                <el-input placeholder="" show-password v-model="from2.once" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_line"></div>
            <div class="other_main_dig_btn">
                <div class="other_main_dig_btn_sure" @click="clickSure">{{$t("确认")}}</div>
                <div class="other_main_dig_btn_cancle" @click="dialogVisible = false">{{$t("取消")}}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            fileList: [],
            value: 1,
            dialogVisible: false,
            from: {},
            from2: {
                old: '',
                new: '',
                once: ''
            },
            value: '',
            accountList: [],
            fj_root: '',
            btn_loading: false
        }
    },
    mounted() {
        this.accountList = JSON.parse(sessionStorage.getItem('rym_account'))
        this.value = this.accountList[0].image
        this.init()
    },
    methods: {
        init() {
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取人员基本信息","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*216;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({"usercode":""})
            }))

            res.then(response => {
               this.from = response?.data?.data?.userInfo
               if (this.from?.photo_pic) {
                    this.fj_root = this.from.photo_pic.split('/db_01/')[1]
                    this.fileList = [
                        {
                            url: this.$store.state.host + '/entrance' + this.from.photo_pic
                        }
                    ]
               }
               if (this.from?.account_type) {
                 this.accountList.forEach(r => {
                    if (r.sid == this.from.account_type) {
                        this.value = r.image
                    }
                 })
               }
            })
        },
        sure() {
            if (this.fileList.length == 0) {
                this.$message.error(this.$t('请上传账户头像'));
                return
            }
            if (!this.from.tel) {
                this.$message.error(this.$t('请填写手机号'));
                return
            }
            // if (!this.from.account) {
            //     this.$message.error('请填写社媒账号');
            //     return
            // }
            if (this.btn_loading) {
                return false
            }
            this.btn_loading = true
            const res2 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"人员-修改","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*206;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({
                    usrname: this.from.usrname,
                    orgcode: this.from.orgcode,
                    // sex: 1,
                    // semail: this.from.semail,
                    // ident: '',
                    tel: this.from.tel,
                    usrcode: this.from.usrcode,
                    photo_pic: this.fj_root,
                    account: this.from.account,
                    account_type: this.getId(this.value)
                })
            }))
            res2.then(response => {
                this.btn_loading = false
                if (response?.data?.id == 0) {
                    this.$message.success(this.$t('操作成功'));
                    sessionStorage.setItem('rym_avatar', this.fj_root)
                    this.$emit('getImg')
                } else {
                    this.$message.error(response?.data?.message);
                }
            })
        },
        getId(image) {
            let val = ''
            this.accountList.forEach(r => {
                if (r.image == image) {
                    val = r.sid
                }
            })
            return val
            
        },
        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },
        openPwd() {
            this.from2 = {
                old: '',
                new: '',
                once: ''
            }
            this.dialogVisible = true;
        },
        clickSure() {
            if (!this.from2.old || !this.from2.new) {
                return
            }
            if (this.from2.new != this.from2.once) {
                this.$message.error(this.$t('俩次新密码不一致'));
                return
            }
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"人员-修改密码","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*212;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({
                    "oPwd": btoa(encodeURIComponent(this.from2.old)),
                    "nPwd": btoa(encodeURIComponent(this.from2.new)),
                    "usercode": this.from.usrcode
                })
            }))
            res.then(response => {
                if (response?.data?.id == 0) {
                    this.$message.success(this.$t('操作成功'));
                    this.dialogVisible = false
                } else {
                    this.$message.error(response?.data?.message);
                }
                
            })
        },
        exit() {
            this.$confirm(this.$t('log_out'), this.$t('please_confirm'), {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.token = ''
                sessionStorage.removeItem("rym_token");
                sessionStorage.removeItem("rym_snkey");
                sessionStorage.removeItem("rym_user");
                sessionStorage.removeItem("rym_index");
                sessionStorage.removeItem("rym_scm");
                this.$router.push('/')
            }).catch(() => { });
        },
        onExceed() {
            this.$message.error(this.$t('只能上传一张图片'));
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type.indexOf('image') > -1;
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error(this.$t('只能上传图片'));
            }
            if (!isLt2M) {
                this.$message.error(this.$t('2MB'));
            }
            return isJPG && isLt2M;
            // return isLt2M
        },
        //查看
        handlePreview(file) {
            window.open(this.fileList[0].url)
        },
        //上传
        upload(file) {
            let formData = new FormData(); // 创建FormData对象
            formData.append('file', file.file); // 添加文件到formData

            axios({
                method: 'post',
                url: this.$store.state.host + '/entrance///sysupd?updid=40&dbid=01&cellID=_USERIMG&snkey=' + sessionStorage.getItem('rym_snkey'),
                data: formData,
                responseType: 'json',
                withCredentials: false,
                headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为multipart/form-data类型
            })
                .then((response) => {
                    this.fj_root = response.data.data.fj_root
                    // let url = response.data.split('uploads\\')[1]
                    this.fileList = [
                        {
                            url: this.$store.state.host + '/entrance/db_01/' + this.fj_root
                        }
                    ]
                })
                .catch((error) => {
                    console.error(error); // 处理错误信息
                });
        },
        // 删除
        handleRemove(file) {
            this.fileList = []
        },
    }
}
</script>

<style>

.user_drop2 .el-dropdown-menu__item:not(.is-disabled):hover{
    background:rgba(243, 247, 251, 1)!important;
    border-radius:2px;
    
}
.mt90{
    margin-top: 90px;
    text-align: center;
}
.mt90_edit{
    width: 160px;
    height: 32px;
    background: #ffffff;
    border: 1px solid;
    border-color: #006bfc;
    border-radius: 2px;
    display: inline-block;
    line-height: 32px;
    text-align: center;
    color:#006bfc;
    font-size:14px;
    cursor: pointer;
}
.mt90_del{
    width: 160px;
    height: 32px;
    background: #ffffff;
    border: 1px solid;
    border-color: #dddfe7;
    border-radius: 2px;
    display: inline-block;
    line-height: 32px;
    text-align: center;
    color:rgba(51, 51, 51, 1);
    font-size:14px;
    cursor: pointer;
    margin-left: 20px;
}
</style>