<template>
    <div class="other_main">
        <div class="organization">
            <span>{{$t("组织架构")}}</span>
        </div>
        <div class="organization_line"></div>
        <div class="organization_flex">
            <div class="organization_flex_left">
                <div class="organization_flex_left_top">
                    <div class="organization_flex_left_top_icon"></div>
                    <div class="organization_flex_left_top_name">
                        <span>{{$t("组织树")}}</span>
                        <div class="organization_flex_left_top_btn" @click="addClick">{{$t("新增部门")}}</div>
                    </div>
                </div>
                <el-tree :data="data" node-key="id" default-expand-all :expand-on-click-node="false" :props="defaultProps">
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span @click="clickId(data)" class="custom-tree-node-span"><span class="label">{{ node.label
                        }}</span><span class="tree_number">({{ data.peopleNum || 0 }})</span></span>
                        <span>
                            <div class="tree_img2" v-if="node.level != 1" @click="clickEdit(data)"></div>
                            <!-- <div class="tree_img1"></div> -->
                            <div class="tree_img3" v-if="node.level != 1" @click="clickdel(data)"></div>
                        </span>
                    </span>
                </el-tree>
            </div>
            <div class="organization_flex_right" v-if="name">
                <div class="organization_flex_right_top">{{ name }}</div>
                <el-table :data="tableData" style="width: 100%" header-cell-class-name="spenHeader">
                    <el-table-column type="index" label="#" width="100" align="center">
                    </el-table-column>
                    <el-table-column prop="usrname" :label="$t('姓名')" width="180">
                    </el-table-column>
                    <el-table-column :label="$t('社媒账号')" width="180">
                    <template slot-scope="scope">
                        <div class="user_table_flex">
                            <div v-for="item in accountList" :key="item.sid">
                                <el-image v-if="scope.row.account_type == item.sid" style="width: 20px; height: 20px;margin-right: 5px;"
                                :src="$store.state.host + '/entrance' + item.image"></el-image>
                            </div>
                            <span>{{ scope.row.account }}</span>
                        </div>
                    </template>
                </el-table-column>
                    <el-table-column prop="tel" :label="$t('手机号')" width="180">
                    </el-table-column>
                    <el-table-column prop="semail" :label="$t('邮箱1')">
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="total" class="organization_flex_right_page"
                    :page-size="10" :current-page="totalPage" @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="edit ? $t('编辑部门') : $t('新增部门')" :visible.sync="dialogVisible" width="460px">
            <div class="other_main_dig_flex" v-if="edit">
                <div class="other_main_dig_flex_left">
                    <span class="red">*</span>
                    {{$t("编码")}}
                </div>
                <el-input placeholder="" v-model="from.orgcode" :disabled="true" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    <span class="red">*</span>
                    {{$t("部门名称")}}
                </div>
                <el-input placeholder="" v-model="from.orgname" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    {{$t("简称")}}
                </div>
                <el-input placeholder="" v-model="from.sname" class="other_main_dig_flex_input">
                </el-input>
            </div>
            <div class="other_main_dig_flex">
                <div class="other_main_dig_flex_left">
                    {{$t("上级部门")}}
                </div>
                <el-select v-model="from.parentId" placeholder="" class="other_main_dig_flex_input"  popper-class="modifyStyle">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="other_main_dig_line"></div>
            <div class="other_main_dig_btn">
                <div class="other_main_dig_btn_sure" @click="clickSure" v-loading="btn_loading">{{$t("确认")}}</div>
                <div class="other_main_dig_btn_cancle" @click="dialogVisible = false">{{$t("取消")}}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            data: [],
            defaultProps: {
                children: 'child',
                label: 'orgname',
                id: 'orgcode'
            },
            name: '',
            tableData: [],
            edit: false,
            dialogVisible: false,
            options: [],
            from: {
                orgcode: '',
                orgname: '',
                sname: '',
                parentId: ''
            },
            total: 1,
            orgcode: '',
            totalPage: 1,
            accountList: [],
            btn_loading:false,
        };
    },
    mounted() {
        this.accountList = JSON.parse(sessionStorage.getItem('rym_account'))
        this.init()
    },
    methods: {
        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },
        init() {
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取组织机构","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*200;0;0"}',
                usercode: sessionStorage.getItem('rym_user')
            }))

            res.then(response => {
                this.data = response?.data?.data?.value || []
                this.options = []
                this.data.forEach(m => {
                    this.options.push({
                        value: m.orgcode,
                        label: m.orgname
                    })
                    if (m.child) {
                        m.child.forEach(n => {
                            this.options.push({
                                value: n.orgcode,
                                label: n.orgname
                            })
                        })
                    }
                })
            })
        },
        clickId(data) {
            this.totalPage = 1
            this.orgcode = data.orgcode
            this.name = data.orgname
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取人员列表-部门","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*203;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({
                    orgcode: data.orgcode,
                    page: {
                        page: this.totalPage,
                        pagesize: 10
                    }
                })
            }))
            res.then(response => {
                if (response?.data?.data?.data) {
                    this.tableData = response.data.data.data
                    this.total = response.data.data.total
                } else {
                    this.$message.error(response.data.message);
                    this.totalPage = 1
                }
            })
        },
        pageChange(page) {
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取人员列表-部门","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*203;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({
                    orgcode: this.orgcode,
                    page: {
                        page: page,
                        pagesize: 10
                    }
                })
            }))
            res.then(response => {
                if (response?.data?.data?.data) {
                    this.tableData = response.data.data.data
                    this.total = response.data.data.total
                    this.totalPage = page
                } else {
                    this.$message.error(response.data.message);
                    this.totalPage = 1
                }

            })
        },
        addClick() {
            this.edit = false
            this.from = {
                orgcode: '',
                orgname: '',
                sname: '',
                parentId: ''
            }
            this.dialogVisible = true
        },
        clickdel(data) {
            this.$confirm(this.$t('是否继续删除'), ' ', {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('取消'),
                type: 'warning'
            }).then(() => {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"作废组织机构","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*204;0;0"}',
                    usercode: sessionStorage.getItem('rym_user'),
                    env: JSON.stringify({
                        orgcode: data.orgcode,
                        eymd: true
                    })
                }))
                res.then(response => {
                    this.dialogVisible = false
                    this.$message.success(this.$t('操作成功'));
                    this.init()
                })
            }).catch(() => {});
        },
        clickSure() {
            if (!this.from.orgname) {
                this.$message.error(this.$t('请输入部门名称'));
            } else {
                if(this.btn_loading){
                    return;
                }
                this.btn_loading = true;
                if (this.edit) {
                    const res = axios(this.getOptions({
                        apiId: 'dlgclassrun',
                        dbid: '01',
                        btn: '{"cmd":"DLG","name":"修改组织机构","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*204;0;0"}',
                        usercode: sessionStorage.getItem('rym_user'),
                        env: JSON.stringify({
                            orgname: this.from.orgname,
                            orgcode: this.from.orgcode,
                            parentId: this.from.parentId
                        })
                    }))
                    res.then(response => {
                        this.dialogVisible = false
                        this.$message.success(this.$t('操作成功'));
                        this.init()
                    })
                } else {
                    const res = axios(this.getOptions({
                        apiId: 'dlgclassrun',
                        dbid: '01',
                        btn: '{"cmd":"DLG","name":"新建组织机构","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*201;0;0"}',
                        usercode: sessionStorage.getItem('rym_user'),
                        env: JSON.stringify({
                            orgname: this.from.orgname,
                            parentId: this.from.parentId
                        })
                    }))
                    res.then(response => {
                        this.dialogVisible = false
                        this.$message.success(this.$t('操作成功'));
                        this.init()
                        setTimeout(() => {
                            this.btn_loading = false;
                        }, 500);
                    })
                }


            }
        },
        clickEdit(data) {
            this.from = JSON.parse(JSON.stringify(data))
            this.edit = true
            this.dialogVisible = true
        }
    }
}
</script>


<style>
@import url(../assets/organization.css);
</style>