<template>
    <div style="min-width:1400px">
        <div class="main_bg"
            :style="{ backgroundImage: homeIndex == 0 ? ('url(' + require('../assets/img/482x.png') + ')') : ('url(' + require('../assets/img/' + homeIndex + '.png') + ')'), height: homeIndex == 0 ? '100vh' : '454px', minHeight: homeIndex == 0 ? '900px' : '454px', position: homeIndex == 0 ? '' : 'relative' }">
            <div class="main_bg_left"
                :style="{ backgroundImage: homeIndex == 0 ? ('url(' + require('../assets/img/82x.png') + ')') : '' }">
                <div class="main_bg_top">
                    <span class="main_bg_top_title">{{ $t('name_logo') }}</span>
                    <div class="main_bg_top_right">
                        <div class="main_bg_top_right_home">
                            {{ $t('首页') }}
                            <div class="main_bg_top_right_home_img"></div>
                        </div>
                        <el-dropdown placement="bottom-start" trigger="click">
                            <span class="main_bg_top_drop">
                                {{ $t('应用服务') }}
                                <div class="main_bg_top_drop_img"></div>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(1)">{{ $t('btob') }}</div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(2)">{{ $t('warehouse') }}</div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(3)">{{ $t('club') }}</div>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <div class="main_bg_top_drop_div" @click="changeIndex(4)">{{ $t('xingluo') }}</div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <span><a href="#about">{{ $t("联系我们") }}</a></span>
                    </div>
                </div>
                <div>
                    <div class="main_bg_img" :style="{ marginTop: $i18n.locale == 'zh' ? '228px' : '124px' }">
                        <span class="main_bg_img_title" v-if="type == 1">{{ $t('name_logo') }}</span>
                        <span class="main_bg_img_title" v-if="type == 2">{{ $t('云路天成') }}</span>
                        <div class="main_bg_img_icon"></div>
                    </div>
                    <div class="main_bg_desc" v-if="type == 1">
                        {{ $t('以数字化') }}<span class="main_bg_desc_yellow">{{ $t('btob') }}/{{ $t('warehouse') }}/{{
                            $t('club')
                        }}/{{ $t('xingluo') }}</span>{{ $t('四维一体') }}
                    </div>
                    <div class="main_bg_desc" v-if="type == 2">
                        {{ $t('通过数字创新') }}
                    </div>
                    <div class="main_bg_btn_div">
                        <div class="main_bg_btn" @click="openEnterpriseRegistration">
                            {{ $t('立即体验') }}
                            <div class="main_bg_btn_icon"></div>
                        </div>
                        <div class="main_bg_btn_right" v-if="type == 1">
                            <div class="main_bg_btn_left_icon"></div>
                            <div class="main_bg_btn_right_icon" @click="type = 2"></div>
                        </div>
                        <div class="main_bg_btn_right" v-if="type == 2">
                            <div class="main_bg_btn_left_icon2" @click="type = 1"></div>
                            <div class="main_bg_btn_right_icon2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_bg_right">
                <div class="main_bg_right_lang">
                    <span
                        :class="$i18n.locale == 'zh' ? 'main_bg_right_lang_z main_bg_right_lang_active' : 'main_bg_right_lang_z'"
                        @click="langClick('zh')">{{ $t('中') }}</span>
                    <span
                        :class="$i18n.locale == 'zh' ? 'main_bg_right_lang_e' : 'main_bg_right_lang_e main_bg_right_lang_active'"
                        @click="langClick('en')">EN</span>
                </div>
                <!-- <div class="main_bg_right_img" @click="openDialog"></div> -->

                <el-dropdown>
                    <span class="el-dropdown-link">
                        <div class="main_bg_right_img"></div>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="!token">
                            <div class="btn_top" @click="openDialog">{{ $t('login') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="!token">
                            <div class="btn_top" @click="openEnterpriseRegistration">{{ $t('register') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="token">
                            <div class="btn_top" @click="exit">{{ $t('exit') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="btn_top" @click="download">{{ $t('download') }}</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="main_list">
            <div class="main_list_1">
                <div class="main_list_1_place">
                    <div class="main_list_1_place_title">
                        <el-image :src="require('../assets/img/8@2x.png')" class="main_list_1_place_title_img"></el-image>
                        <span class="main_list_1_place_title_span">{{ $t('买家需求列表') }}</span>
                        <span class="main_list_1_place_title_right" style="cursor:pointer" @click="openDialog">More +</span>
                    </div>
                    <div class="main_list_1_place_line"></div>
                    <div class="over_div">
                        <div v-for="(item, index) in list1" :key="index" class="main_list_1_place_1">
                            <div class="main_list_1_place_1_bg">
                                <div class="main_list_1_place_flex">
                                    <span class="main_list_1_place_1_title">{{ item.goods_name }}</span>
                                    <div class="main_list_1_place_1_1" v-if="item.d_classify_name">
                                        <el-image :src="require('../assets/img/69@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                        <span class="main_list_1_place_1_1_span">{{ item.d_classify_name }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_2" v-if="item.continent_name">
                                        <span class="main_list_1_place_1_1_span">{{ item.continent_name }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_2" v-if="item.d_category">
                                        <span class="main_list_1_place_1_1_span">{{item.d_category == 1 ? $t('大宗货物') : $t('跨境商品')}}</span>
                                    </div>
                                </div>
                                <div class="mtop12">
                                    <div class="main_list_1_place_1_3" v-if="item.source_area_name">
                                        <el-image :src="require('../assets/img/70@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                        <span class="main_list_1_place_1_1_span">
                                            {{ item.source_area_name }}
                                            <span v-if="item.source_city">
                                                -{{ item.source_city }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="main_list_1_place_1_3" v-if="item.brand_id_name">
                                        <span class="main_list_1_place_1_1_span">{{item.brand_id_name}}</span>
                                    </div>
                                    <div class="main_list_1_place_1_3" v-if="item.d_numb_des">
                                        <span class="main_list_1_place_1_1_span">{{item.d_numb_des}}</span>
                                    </div>
                                    <!-- <div class="main_list_1_place_1_3" v-for="(t,i) in item.settings" :key="i">
                                        <span class="main_list_1_place_1_1_span">{{t.setting_name }}&nbsp;{{ item.settings[0].setting_value }}</span>
                                    </div> -->
                                </div>
                            </div>
                            <div class="mtop12_line"></div>
                            <div class="main_list_1_place_2_bg">
                                <el-image :src="$store.state.host + '/entrance' + item.synInfo.logo" class="main_list_1_place_2_bg_img">
                                    <div slot="error" class="image-slot">
                                        <el-image :src="require('../assets/img/188.png')"></el-image>
                                    </div>
                                </el-image>
                                <span class="main_list_1_place_2_bg_span">{{ item.synInfo.corporatename }}</span>
                                <!-- <el-image :src="require('../assets/img/67@2x.png')" class="main_list_1_place_2_bg_3_img"></el-image>
                                <el-image :src="require('../assets/img/1130@2x.png')" class="main_list_1_place_2_bg_3_img"></el-image>
                                <el-image :src="require('../assets/img/1122@2x.png')" class="main_list_1_place_2_bg_3_img"></el-image>
                                <el-image :src="require('../assets/img/66@2x.png')" class="main_list_1_place_2_bg_4_img"></el-image> -->
                                <div class="main_list_1_place_2_bg_flex">
                                    <div class="main_list_1_place_2_bg_flex_btn" @click="openDialog">{{ $t('查看详情') }}</div>
                                </div>
                            </div>
                            <div class="main_list_1_place_1_right">
                                <el-image :src="require('../assets/img/15@2x.png')" class="main_list_1_place_1_right_img"></el-image>
                            </div>
                            <div class="main_list_1_place_2_right">
                                <el-image :src="require('../assets/img/73@2x.png')" class="main_list_1_place_2_right_img"></el-image>
                            </div>
                        </div>
                    </div>
                    <div style="float:right;margin-top: 20px;">
                        <el-pagination
                            @current-change="getList1"
                            :current-page="page1.page"
                            layout="prev, pager, next"
                            :total="page1.total">
                        </el-pagination>
                    </div>
                </div>
                <div class="main_list_1_place mtop20">
                    <div class="main_list_1_place_title">
                        <el-image :src="require('../assets/img/8@2x.png')" class="main_list_1_place_title_img"></el-image>
                        <span class="main_list_1_place_title_span">{{ $t('卖家商品列表') }}</span>
                        <span class="main_list_1_place_title_right" style="cursor:pointer" @click="openDialog">More +</span>
                    </div>
                    <div class="main_list_1_place_line"></div>
                    <div class="over_div">
                        <div v-for="(item, index) in list2" :key="index" class="main_list_1_place_1">
                            <div class="main_list_1_place_1_bg">
                                <div class="main_list_1_place_flex">
                                    <span class="main_list_1_place_1_title">{{ item.goods_name }}</span>
                                    <div class="main_list_1_place_1_1" v-if="item.goods_category_name">
                                        <el-image :src="require('../assets/img/69@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                        <span class="main_list_1_place_1_1_span">{{ item.goods_category_name }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_2" v-if="item.continent_name">
                                        <span class="main_list_1_place_1_1_span">{{ item.continent_name }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_2" v-if="item.d_category">
                                        <span class="main_list_1_place_1_1_span">{{item.d_category == 1 ? $t('大宗货物') : $t('跨境商品')}}</span>
                                    </div>
                                </div>
                                <div class="mtop12" style="min-height:30px">
                                    <div class="main_list_1_place_1_3_title">
                                        <span>{{ $t('品名') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.name_name || '-' }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_3_title">
                                        <span>{{ $t('品种') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.brand_id_name || '-' }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_3_title">
                                        <span>{{ $t('等级') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.glevel || '-' }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_3_title">
                                        <span>{{ $t('处理方式') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.hmethod || '-' }}</span>
                                    </div>

                                    <!-- <div class="main_list_1_place_1_3_title" v-for="(t, i) in item.settings" :key="i">
                                        <span>{{ t.setting_name }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{t.setting_value}}</span>
                                    </div>
                                    <div class="main_list_1_place_1_3_title" v-if="item.goods_spec">
                                        <span>{{ $t('规格') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.goods_spec }}</span>
                                    </div>
                                    <div v-if="item.goods_unit_name" class="main_list_1_place_1_3_title">{{ $t('数量') }}</div>
                                    <div class="main_list_1_place_1_3" v-if="item.goods_unit_name">
                                        <span class="main_list_1_place_1_1_span">{{ item.goods_unit_name }}</span>
                                    </div> -->
                                    <!-- <div class="main_list_1_place_1_3">
                                        <span class="main_list_1_place_1_1_span">30</span>
                                        <span class="main_list_1_place_1_1_desc">Kg/每袋</span>
                                    </div>
                                    <div class="main_list_1_place_1_3">
                                        <span class="main_list_1_place_1_1_span">200</span>
                                        <span class="main_list_1_place_1_1_desc">袋</span>
                                    </div> -->
                                    <!-- <div class="main_list_1_place_1_3">
                                        <span class="main_list_1_place_1_1_span">$20</span>
                                        <span class="main_list_1_place_1_1_desc">每kg</span>
                                    </div> -->
                                    <div class="main_list_1_place_1_3_right" style="display:flex;align-items:center">
                                        <span style="font-weight: 400;font-size: 12px;color: #666666;margin-right:12px">{{ $t('单价') }}</span>
                                        <span style="color:#FF9D00">******</span>    
                                    </div>
                                </div>
                                <div>
                                    <div class="main_list_1_place_1_3_title">
                                        <span>{{ $t('产地') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.source_area_name || '-' }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_3_title">
                                        <span>{{ $t('产区') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.source_city || '-' }}</span>
                                    </div>
                                    <div class="main_list_1_place_1_3_title">
                                        <span>{{ $t('重量') }}</span>
                                        <span class="main_list_1_place_1_3_title_desc">{{ item.rough_weight || '-' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mtop12_line"></div>
                            <div class="main_list_1_place_2_bg">
                                <el-image :src="$store.state.host + '/entrance' + item.synInfo.logo" class="main_list_1_place_2_bg_img">
                                    <div slot="error" class="image-slot">
                                        <el-image :src="require('../assets/img/188.png')"></el-image>
                                    </div>
                                </el-image>
                                <span class="main_list_1_place_2_bg_span">{{ item.synInfo.corporatename }}</span>
                                <!-- <el-image v-if="item == 1" :src="require('../assets/img/67@2x.png')" class="main_list_1_place_2_bg_3_img"></el-image>
                                <el-image v-if="item == 2" :src="require('../assets/img/1130@2x.png')" class="main_list_1_place_2_bg_3_img"></el-image>
                                <el-image v-if="item == 2" :src="require('../assets/img/1122@2x.png')" class="main_list_1_place_2_bg_3_img"></el-image>
                                <el-image v-if="item == 1" :src="require('../assets/img/66@2x.png')" class="main_list_1_place_2_bg_4_img"></el-image> -->
                                <div class="main_list_1_place_2_bg_flex">
                                    <div class="main_list_1_place_2_bg_flex_btn" @click="openDialog">{{ $t('查看详情') }}</div>
                                </div>
                            </div>
                            <div class="main_list_1_place_1_right">
                                <el-image :src="require('../assets/img/15@2x.png')" class="main_list_1_place_1_right_img"></el-image>
                            </div>
                            <div class="main_list_1_place_2_right">
                                <el-image :src="require('../assets/img/73@2x.png')" class="main_list_1_place_2_right_img"></el-image>
                            </div>
                        </div>
                    </div>
                    <div style="float:right;margin-top: 20px;">
                        <el-pagination
                            @current-change="getList2"
                            :current-page="page2.page"
                            layout="prev, pager, next"
                            :total="page2.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="main_list_2"></div>
            <div class="main_list_3">
                <div class="main_list_3_img1" @click="openDialog">
                    <div class="main_list_3_img1_title">{{$t("买家需求发布")}}</div>
                    <div class="main_list_3_img1_desc">{{ $t('供应直达') }}</div>
                </div>
                <div class="main_list_3_img2" @click="openDialog">
                    <div class="main_list_3_img1_title">{{$t("卖家商品发布")}}</div>
                    <div class="main_list_3_img1_desc">{{ $t('优质之选') }}</div>
                </div>
                <div class="main_list_3_img3"></div>
            </div>
        </div>
        <div class="main_map_logo"></div> 
        <div class="main_map">
            <div class="main_map_desc">
                <div>
                    <div class="main_map_desc_icon"></div>
                    <span class="main_map_desc_title">{{ $t('平台介绍') }}</span>
                </div>
                <div class="main_map_desc_top">{{ $t('DIIT') }}</div>
                <div class="main_map_desc_m">{{ $t('中运数字') }}</div>
                <div class="main_map_line"></div>
                <div class="main_map_flex">
                    <div class="main_map_flex_1">
                        <div>
                            <div class="main_map_flex_left_img1"></div>
                            <div class="main_map_flex_right_img1"></div>
                        </div>
                        <div class="main_map_flex_title">{{ $t('智能化') }}</div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('实现统一OS') }}
                        </div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('流程数字化智能化转化') }}
                        </div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('节点管控') }}
                        </div>
                    </div>
                    <div class="main_map_flex_1">
                        <div>
                            <div class="main_map_flex_left_img2"></div>
                            <div class="main_map_flex_right_img2"></div>
                        </div>
                        <div class="main_map_flex_title">{{ $t('超级终端') }}</div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('硬件能力可以弹性扩展') }}
                        </div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('硬件互通资源共享') }}
                        </div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('角色权限任务进程化') }}
                        </div>
                    </div>
                    <div class="main_map_flex_1">
                        <div>
                            <div class="main_map_flex_left_img3"></div>
                            <div class="main_map_flex_right_img3"></div>
                        </div>
                        <div class="main_map_flex_title">{{ $t('定制化开发') }}</div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('面向多区域实现云部署') }}
                        </div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('定制化开发前端应用') }}
                        </div>
                        <div class="main_map_flex_desc">
                            <span class="main_map_flex_desc_span"></span>
                            {{ $t('本地化服务商接口') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_map_img"></div>
        </div>
        <div class="main_flex">
            <div class="main_flex_1 main_flex_img1">
                <div class="main_flex_img1_icon"></div>
                <div class="main_flex_name">{{$t('btob')}}</div>
                <div class="main_flex_name_en">International Trade Operation Platform</div>
                <div class="main_flex_add"></div>
                <div class="main_flex_details_desc">{{ $t('details_desc') }}</div>
                <div class="main_flex_btn" @click="getUrl(1)">
                    {{ $t('查看详情') }}
                    <div class="main_flex_btn_icon"></div>
                </div>
            </div>
            <div class="main_flex_1 main_flex_img2">
                <div class="main_flex_img2_icon"></div>
                <div class="main_flex_name">{{$t('warehouse')}}</div>
                <div class="main_flex_name_en">Digital Delivery Warehouse</div>
                <div class="main_flex_add"></div>
                <div class="main_flex_details_desc">{{ $t('warehouse_desc') }}</div>
                <div class="main_flex_btn" @click="getUrl(2)">
                    {{ $t('查看详情') }}
                    <div class="main_flex_btn_icon"></div>
                </div>
            </div>
            <div class="main_flex_1 main_flex_img3">
                <div class="main_flex_img3_icon"></div>
                <div class="main_flex_name">{{$t('club')}}</div>
                <div class="main_flex_name_en">Ecross Border Club</div>
                <div class="main_flex_add"></div>
                <div class="main_flex_details_desc">{{ $t('club_desc') }}</div>
                <div class="main_flex_btn" @click="getUrl(3)">
                    {{ $t('查看详情') }}
                    <div class="main_flex_btn_icon"></div>
                </div>
            </div>
            <div class="main_flex_1 main_flex_img4">
                <div class="main_flex_img4_icon"></div>
                <div class="main_flex_name">{{ $t('xingluo') }}</div>
                <div class="main_flex_name_en">Star Scattered Market Place</div>
                <div class="main_flex_add"></div>
                <div class="main_flex_details_desc">{{ $t('xingluo_desc') }}</div>
                <div class="main_flex_btn" @click="getUrl(4)">
                    {{ $t('查看详情') }}
                    <div class="main_flex_btn_icon"></div>
                </div>
            </div>
        </div>
        <div class="main_footer">
            <div style="display:flex">
                <div style="flex:1">
                   <div style="display:flex">
                    <div class="main_footer_1">
                        <div class="main_footer_1_img">
                            <span class="main_footer_1_title">{{ $t('instruction') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" @click="openPDF(1)">{{ $t('btob') }}</div>
                        <div class="main_footer_1_footer_con" @click="openPDF(2)">{{ $t('warehouse') }}</div>
                        <div class="main_footer_1_footer_con" @click="openPDF(3)">{{ $t('club') }}</div>
                        <div class="main_footer_1_footer_con" @click="openPDF(4)">{{ $t('xingluo') }}</div>
                    </div>
                    <div class="main_footer_2">
                        <div class="main_footer_2_img">
                            <span class="main_footer_1_title">{{ $t('help') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" @click="download">{{ $t('download2') }}</div>
                        <div class="main_footer_1_footer_con" @click="openkf">{{ $t('support') }}</div>
                    </div>
                    <div class="main_footer_3">
                        <div class="main_footer_3_img">
                            <span class="main_footer_1_title">{{ $t('legalSupport') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" @click="legal($t('serviceTerms'))">{{ $t('serviceTerms') }}
                        </div>
                        <div class="main_footer_1_footer_con" @click="legal($t('serviceTerms2'))">{{ $t('serviceTerms2') }}
                        </div>
                    </div>
                    <div style="flex:1"><div class="main_footer_4_line"></div></div>
                   </div>
                </div>
                <div>

                    <div class="main_footer_4" id="about">
                        <div class="main_footer_4_img">
                            <span class="main_footer_1_title">{{ $t('联系我们') }}</span>
                        </div>
                        <div class="main_footer_1_footer_con" style="width: 335px">{{ $t('地址') }}</div>
                        <div class="main_footer_1_footer_con">{{ $t('邮箱') }}
                            <a class="support_span" href="mailto:support@diit-operation-platform.com">support@diit-operation-platform.com</a>
                        </div>
                        <div class="main_footer_1_footer_con">{{ $t('电话') }}+603-2181 8555</div>
                    </div>
                </div>
            </div>
            <div class="main_footer_line"></div>
            <div class="main_footer_ver">©2023-2024 Zhong Yun Supply Chain Management Pte Ltd 版权所有</div>
        </div>
        <el-dialog :visible.sync="dialogVisible" v-if="dialogVisible" width="1200px" :modal="true" z-index="1000">
            <div class="dia_flex">
                <div class="dia_flex_left"></div>
                <div class="dia_flex_right">
                    <div class="dia_en">
                        WELCOME TO LOG IN
                        <span class="dia_wel">{{ $t('welcome') }}</span>
                    </div>
                    <div class="mt70">
                        <el-input :placeholder="$t('user_name')" v-model="form.usercode">
                            <template slot="prepend">
                                <div class="dia_img1"></div>
                                <div class="dia_line"></div>
                            </template>
                        </el-input>
                        <el-input :placeholder="$t('pwd')" v-model="form.pwd" class="mt20" show-password>
                            <template slot="prepend">
                                <div class="dia_img2"></div>
                                <div class="dia_line"></div>
                            </template>
                        </el-input>
                        <div class="input_flex mt20">
                            <el-input :placeholder="$t('验证码')" v-model="form.code" class="spec">
                                <template slot="prepend">
                                    <div class="dia_img3"></div>
                                    <div class="dia_line"></div>
                                </template>
                            </el-input>
                            <el-image @click="getRound" :src="MathRadImg" style="width: 114px;display: inline-block;height: 40px;cursor: pointer;"></el-image>
                            <!-- <div class="dia_img4"></div> -->
                        </div>
                        <div class="mt24">
                            <el-checkbox v-model="checked">{{ $t('记住账号') }}</el-checkbox>
                            <!-- <span class="mt24_span">{{ $t('忘记密码') }}</span> -->
                        </div>
                        <div class="login_btn" @click="submitForm">
                            <span v-html="$t('登录')"></span>
                        </div>
                        <div style="margin-top:20px;text-decoration: underline;cursor: pointer;display: inline-block;" @click="openEnterpriseRegistration">{{ $t('去注册') }}</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import Top from '../component/navigation.vue'
import Footer from '../component/footer.vue'
export default {
    name: "Index",
    components: {
        Top,
        Footer
    },
    mounted() {
        if (sessionStorage.getItem('rym_token')) {
            this.token = sessionStorage.getItem('rym_token')
        }
        if (this.$route.query.locale) {

            if (this.$route.query.locale == 'en') {
                this.$i18n.locale = 'en'
            } else {
                this.$i18n.locale = 'zh'
            }
        }
        this.init()
        
    },
    data() {
        return {
            imgList: [
                {
                    zh: 'https://www.diit-operation-platform.com/demo/img/banner.jpg',
                    en: 'https://www.diit-operation-platform.com/demo/img/banner_en.jpg'
                },
                {
                    zh: 'https://www.diit-operation-platform.com/demo/img/banner2.jpg',
                    en: 'https://www.diit-operation-platform.com/demo/img/banner2_en.jpg'
                }
            ],
            dialogVisible: false,
            dialogVisible_kf: false,
            form: {
                usercode: '',
                pwd: '',
                code: ''
            },
            rules: {
                usercode: [
                    { required: true, message: '', trigger: 'input' }
                ],
                pwd: [
                    { required: true, message: '', trigger: 'input' }
                ],
            },
            token: '',
            type: 1,
            homeIndex: 0,
            checked: false,
            MathRad: '',
            MathRadImg: '',
            list1: [],
            list2: [],
            page1: {
                page: 1,
                total: 0
            },
            page2: {
                page: 1,
                total: 0
            }
        }
    },
    methods: {
        getRound() {
            this.MathRad = Math.random()
            const res = axios(this.getOptions({
                apiId: 'yzm',
                dbid: '01',
                uuid: this.MathRad
            }))
            res.then(res => {
                this.MathRadImg = res?.data?.data?.yzm
            })
        },
        changeIndex(index) {
            // this.homeIndex = index
            this.$router.push('/details?index=' + index)
        },
        download() {
            // this.$alert(this.$t('soon'), '', {
            //     confirmButtonText: this.$t('determine'),
            //     showConfirmButton: false,
            //     callback: action => { }
            // });
            this.$message({
                message: this.$t('soon'),
                type: 'warning'
            });
        },
        openkf() {
            let user_code = sessionStorage.getItem('rym_scm')
            let url = this.$store.state.host + '/entrance/TouristIndex'
            if(user_code){
                url += "?user_code="+user_code
            }
            window.open(url)
        },
        legal(name) {
            window.open(`https://www.diit-operation-platform.com/demo/doc/${name}.pdf`)
        },
        openPDF(index) {
            window.open(`https://www.diit-operation-platform.com/demo/doc/instruction${index}.pdf`)
        },
        getUrl(index) {
            this.$router.push('/details?index=' + index)
        },
        langClick(item) {
            this.$i18n.locale = item
        },
        openDialog() {
            this.getRound()
            this.form = {}
            this.dialogVisible = true
        },
        submitForm(formName) {
            // this.$refs[formName].validate((valid) => {
            // if (valid) {
            const res = axios(this.getOptions({
                ...this.form,
                pwd: btoa(encodeURIComponent(this.form.pwd)),
                apiId: 'login',
                dbid: '01',
                graphicalCode: this.form.code,
                uuid: this.MathRad,
                isCheckLogonRights: false
            }))

            res.then(response => {
                if (response.data.id != -1) {
                    // this.$message.success(this.$t('login_succeeded'));
                    this.dialogVisible = false;
                    sessionStorage.setItem('rym_snkey', response.data.data.snkey);
                    sessionStorage.setItem('rym_scm', response.data.data.user?.deptInfo?.cmcCode);
                    sessionStorage.setItem('rym_gwCode', response.data.data.user?.gwCode || '');
                    sessionStorage.setItem('rym_avatar', response.data.data.user?.avatar || '');
                    this.getUserType();
                } else {
                    this.getRound();
                    this.$message.error(response.data.message);
                }
            })
            // } else {
            //     console.log('error submit!!');
            //     return false;
            // }
            // });
        },
        getUserType(){
            const res1 = axios(this.getOptions({
                usercode: this.form.usercode,
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"用户类型","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*300;0;0"}',
            }))
            res1.then(resp =>{
                let isBuyer = true;
                if(resp.data.id ==0){
                    isBuyer = resp.data.data.buyer;
                }
                sessionStorage.setItem('rym_is_buyer',isBuyer);
                this.getToken();
            })
        },
        getToken(){
            const res2 = axios(this.getOptions({
                usercode: this.form.usercode,
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"查询Token","icon":"iconfont icon-bip-","size":"mini","bIconleft":true,"enable":true,"hasIcon":true,"type":"","hint":"","dlgType":"D","dlgCont":"inetbas.web.outsys.oauth.BipSysTokenServ*301;0;0","dlgSname":"","bdcell":"","etap":"","ftap":"","btnPosit":"2","sourcePage":"","visible":false}',
            }))
            res2.then(response => {
                this.token = response.data.data.token
                sessionStorage.setItem('rym_user', this.form.usercode);
                sessionStorage.setItem('rym_token', this.token);
                this.$router.push('/complete')
                this.dialogVisible = false
                this.$message.success(this.$t('welcome'));
            })
        },
        exit() {
            this.$confirm(this.$t('log_out'), this.$t('please_confirm'), {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.token = ''
                sessionStorage.removeItem("rym_token");
                sessionStorage.removeItem("rym_snkey");
                sessionStorage.removeItem("rym_user");
                // this.$router.push('/')
            }).catch(() => { });
        },


        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },

        openEnterpriseRegistration() {
            window.location.href = this.$store.state.host + '/entrance/EnterpriseRegistrationNew?locale=' + (this.$i18n.locale == 'en' ? 'en' : 'zh-CN')
        },
        getList1(page) {
            this.page1.page = page
            const res2 = axios(this.getOptions({
                usercode: 'portal',
                apiId: 'outlogin',
                dbid: '01'
            }))
            res2.then(response => {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    usercode: 'portal',
                    btn: '{"cmd":"DLG","name":"需求列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*200;0;0"}',
                    env: JSON.stringify({
                        snkey: response.data.data.snkey,
                        page: {
                            currPage: this.page1.page,
                            pageSize: 10
                        },
                        condition: {
                            d_classify: '',
                            source_area: '',
                            goods_name: ''
                        }
                    })
                }))
                res.then(res => {
                    this.list1 = res?.data?.data?.values
                    this.page1.total = res?.data?.data?.page?.total
                })
            })
            
        },
        getList2(page) {
            this.page2.page = page
            const res2 = axios(this.getOptions({
                usercode: 'portal',
                apiId: 'outlogin',
                dbid: '01'
            }))
            res2.then(response => {
                const res3 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    usercode: 'portal',
                    btn: '{"cmd":"DLG","name":"商品列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*200;0;0"}',
                    env: JSON.stringify({
                        snkey: response.data.data.snkey,
                        page: {
                            currPage: this.page2.page,
                            pageSize: 10
                        },
                        condition: {
                            d_classify: '',
                            source_area: '',
                            goods_name: ''
                        }
                    })
                }))
                res3.then(res => {
                    this.list2 = res?.data?.data?.values
                    this.page2.total = res?.data?.data?.page?.total
                })
            })
            
        },
        init() {
            this.getList1(1)
            this.getList2(1)
            // const res2 = axios(this.getOptions({
            //     usercode: 'portal',
            //     apiId: 'outlogin',
            //     dbid: '01'
            // }))
            // res2.then(response => {
                

            //     const res = axios(this.getOptions({
            //         apiId: 'dlgclassrun',
            //         dbid: '01',
            //         usercode: 'portal',
            //         btn: '{"cmd":"DLG","name":"需求列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*200;0;0"}',
            //         env: JSON.stringify({
            //             snkey: response.data.data.snkey,
            //             page: {
            //                 currPage: 1,
            //                 pageSize: 10
            //             },
            //             condition: {
            //                 d_classify: '',
            //                 source_area: '',
            //                 goods_name: ''
            //             }
            //         })
            //     }))
            //     res.then(res => {
            //         this.list1 = res?.data?.data?.values
            //     })



            //     const res3 = axios(this.getOptions({
            //         apiId: 'dlgclassrun',
            //         dbid: '01',
            //         usercode: 'portal',
            //         btn: '{"cmd":"DLG","name":"商品列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*200;0;0"}',
            //         env: JSON.stringify({
            //             snkey: response.data.data.snkey,
            //             page: {
            //                 currPage: 1,
            //                 pageSize: 10
            //             },
            //             condition: {
            //                 d_classify: '',
            //                 source_area: '',
            //                 goods_name: ''
            //             }
            //         })
            //     }))
            //     res3.then(res => {
            //         this.list2 = res?.data?.data?.values
            //     })
            // })
            
        }
    }
}
</script>


<style>@import url(../assets/index_new.css);</style>