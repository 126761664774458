<template>
    <div class="complete">
        <menuList :countryList="countryList" @choose="choose" :country_index="country_index" @handAct="handAct" ref="menu"></menuList>
        <div v-if="menu_index == 2" class="complete_1">
            <div v-if="jurisdiction" class="complete_1_1">
                <div class="complete_1_1_img">
                    <el-carousel height="392px" arrow="never">
                        <el-carousel-item v-for="item in carouselList" :key="item.cid">
                            <el-image :src="$store.state.host + '/entrance' + item.image"
                                style="width:100%;height:100%"></el-image>
                            <div class="complete_1_1_span">{{ item.title }}</div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="complete_1_1_place">
                    <div>
                        <span class="complete_1_1_place_title">{{ $t("甄选平台") }}</span>
                        <el-input :placeholder="$t('请输入内容')" v-model="val" class="input-with-select"
                            prefix-icon="el-icon-search" @change="getChange"></el-input>
                    </div>

                    <div class="complete_1_1_place_line"></div>


                    <div style="border-radius: 6px 6px 0px 6px;;overflow: hidden;box-shadow: 0px 3px 35px 1px rgba(188,188,188,0.2);margin-bottom: 12px;">
                        <div v-if="showTop" class="complete_1_flex_1_place_top" >
                            <div class="complete_1_flex_1_place_top_1" >
                                <el-image :src="require('../assets/img/pic.jpg')"
                                    style="width: 160px;height:44px"></el-image>
                                <div style="position: absolute;top: 11px;left: 24px;">
                                    <el-image :src="require('../assets/img/938@2x.png')"
                                        style="width: 16px;height:16px;position: relative;top: 1px;"></el-image>
                                    <span class="complete_1_flex_1_place_top_1_title">{{ $t('货物需求') }}</span>
                                </div>
                            </div>
                            <div style="padding-top:11px;margin-left: 16px;" @click="changeShowTop(0)">
                                <el-image :src="require('../assets/img/939@2x.png')"
                                    style="width: 15px;height:15px;position: relative;top: 1px;"></el-image>
                                <span class="complete_1_flex_1_place_top_1_title2">{{ $t('商品信息') }}</span>
                            </div>
                        </div>
                        <div v-else class="complete_1_flex_1_place_top">
                            <div class="complete_1_flex_1_place_top_1"  @click="changeShowTop(1)">
                                <div style="position: absolute;top: 11px;left: 24px;">
                                    <el-image :src="require('../assets/img/939@2x.png')"
                                        style="width: 16px;height:16px;position: relative;top: 1px;"></el-image>
                                    <span class="complete_1_flex_1_place_top_1_title" style="color:#fff">{{ $t('货物需求')
                                    }}</span>
                                </div>
                            </div>
                            <div style="margin-left: 16px;position: relative;">
                                <el-image :src="require('../assets/img/1pic.jpg')"
                                    style="width: 164px;height:44px;position: relative;left: -36px;"></el-image>

                                <div style="position: absolute;top: 11px;left: 0;">
                                    <el-image :src="require('../assets/img/938@2x.png')"
                                        style="width: 15px;height:15px;position: relative;top: 1px;"></el-image>
                                    <span class="complete_1_flex_1_place_top_1_title2" style="color:#333">{{ $t('商品信息')
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="complete_1_1_place_auto">
                        <div v-for="(item, index) in shopList" :key="index">
                            <div v-if="item.goods_name" class="main_list_1_place_1" style="margin-top: 0;margin-bottom: 16px;">
                                <!-- 商品列表 -->
                                <template v-if="!showTop">
                                    <div class="main_list_1_place_1_bg">
                                        <div class="main_list_1_place_flex">
                                            <span class="main_list_1_place_1_title">{{ item.goods_name }}</span>
                                            <div class="main_list_1_place_1_1" v-if="item.goods_category_name">
                                                <el-image :src="require('../assets/img/69@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                                <span class="main_list_1_place_1_1_span">{{ item.goods_category_name }}</span>
                                            </div>
                                            <div class="main_list_1_place_1_2" v-if="item.continent_name">
                                                <span class="main_list_1_place_1_1_span">{{ item.continent_name }}</span>
                                            </div>
                                    <div class="main_list_1_place_1_2" v-if="item.d_category">
                                        <span class="main_list_1_place_1_1_span">{{item.d_category == 1 ? $t('大宗货物') : $t('跨境商品')}}</span>
                                    </div>
                                        </div>
                                        <div class="mtop12" style="min-height:30px">
                                            <div class="main_list_1_place_1_3_title" v-if="item.name_name">
                                                <span>{{ $t('品名') }}</span>
                                                <span class="main_list_1_place_1_3_title_desc">{{ item.name_name }}</span>
                                            </div>
                                            <div class="main_list_1_place_1_3_title">
                                                <span>{{ $t('品种') }}</span>
                                                <span class="main_list_1_place_1_3_title_desc">{{ item.brand_id_name }}</span>
                                            </div>
                                            <div class="main_list_1_place_1_3_title" v-if="item.glevel">
                                                <span>{{ $t('等级') }}</span>
                                                <span class="main_list_1_place_1_3_title_desc">{{ item.glevel }}</span>
                                            </div>
                                            <div class="main_list_1_place_1_3_title" v-if="item.hmethod">
                                                <span>{{ $t('处理方式') }}</span>
                                                <span class="main_list_1_place_1_3_title_desc">{{ item.hmethod }}</span>
                                            </div>
                                            <!-- <div class="main_list_1_place_1_3_right">{{ item.price }}</div> -->
                                        </div>
                                        <div>
                                            <div class="main_list_1_place_1_3_title">
                                                <span>{{ $t('产地') }}</span>
                                                <span class="main_list_1_place_1_3_title_desc">{{ item.source_area_name }}</span>
                                            </div>
                                            <div class="main_list_1_place_1_3_title" v-if="item.source_city">
                                                <span>{{ $t('产区') }}</span>
                                                <span class="main_list_1_place_1_3_title_desc">{{ item.source_city }}</span>
                                            </div>
                                            <div class="main_list_1_place_1_3_title" v-if="item.rough_weight">
                                                <span>{{ $t('重量') }}</span>
                                                <span class="main_list_1_place_1_3_title_desc">{{ item.rough_weight }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- 需求列表 -->
                                <template v-else>
                                    <div class="main_list_1_place_1_bg">
                                        <div class="main_list_1_place_flex">
                                            <span class="main_list_1_place_1_title">{{ item.goods_name }}</span>
                                            <div class="main_list_1_place_1_1" v-if="item.d_classify_name">
                                                <el-image :src="require('../assets/img/69@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                                <span class="main_list_1_place_1_1_span">{{ item.d_classify_name }}</span>
                                            </div>
                                            <div class="main_list_1_place_1_2" v-if="item.continent_name">
                                                <span class="main_list_1_place_1_1_span">{{ item.continent_name }}</span>
                                            </div>
                                    <div class="main_list_1_place_1_2" v-if="item.d_category">
                                        <span class="main_list_1_place_1_1_span">{{item.d_category == 1 ? $t('大宗货物') : $t('跨境商品')}}</span>
                                    </div>
                                        </div>
                                        <div class="mtop12">
                                            <div class="main_list_1_place_1_3" v-if="item.source_area_name">
                                                <el-image :src="require('../assets/img/70@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                                <span class="main_list_1_place_1_1_span">
                                                    {{ item.source_area_name }}
                                                    <span v-if="item.source_city">
                                                        -{{ item.source_city }}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="main_list_1_place_1_3" v-if="item.brand_id_name">
                                                <span class="main_list_1_place_1_1_span">{{item.brand_id_name}}</span>
                                            </div>
                                            <div class="main_list_1_place_1_3" v-if="item.d_numb_des">
                                                <span class="main_list_1_place_1_1_span">{{item.d_numb_des}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <div class="mtop12_line"></div>
                                <div class="main_list_1_place_2_bg">
                                    <el-image :src="$store.state.host + '/entrance' + item.synInfo.logo"
                                        class="main_list_1_place_2_bg_img">
                                        <div slot="error" class="image-slot">
                                                        <el-image :src="require('../assets/img/188.png')"></el-image>
                                                    </div>
                                    </el-image>
                                    <span class="main_list_1_place_2_bg_span">{{ item.synInfo.corporatename }}</span>
                                    <!-- <el-image v-if="index == 1" :src="require('../assets/img/67@2x.png')"
                                        class="main_list_1_place_2_bg_3_img"></el-image>
                                    <el-image v-if="index == 2" :src="require('../assets/img/1130@2x.png')"
                                        class="main_list_1_place_2_bg_3_img"></el-image>
                                    <el-image v-if="index == 3" :src="require('../assets/img/1122@2x.png')"
                                        class="main_list_1_place_2_bg_3_img"></el-image>
                                    <el-image v-if="index == 1" :src="require('../assets/img/66@2x.png')"
                                        class="main_list_1_place_2_bg_4_img"></el-image> -->
                                    <div class="main_list_1_place_2_bg_flex">
                                        <div class="main_list_1_place_2_bg_flex_btn" @click="openDetails(item.sid, item.d_category)">{{ $t('查看详情') }}</div>
                                    </div>
                                </div>
                                <div class="main_list_1_place_1_right">
                                    <el-image :src="require('../assets/img/15@2x.png')"
                                        class="main_list_1_place_1_right_img"></el-image>
                                </div>
                                <div class="main_list_1_place_2_right">
                                    <el-image :src="require('../assets/img/73@2x.png')"
                                        class="main_list_1_place_2_right_img"></el-image>
                                </div>
                                <div style="right:20px;position: absolute;top: 50px;" v-if="!showTop">
                                    <span style="font-weight: 400;font-size: 12px;color: #666666;margin-right: 12px;">{{$t('单价') }}</span>
                                    <span style="font-weight: 800;font-size: 18px;color: #FF9D00;">{{item.tb_currency_symbol}}{{ item.price || 0 }}</span>
                                    <span style="font-weight: 400;font-size: 12px;color: #EDB65D;">/{{  item.goods_unit_name }}</span>
                                </div> 
                            </div>
                        </div>
                        <!-- <div v-for="(item, index) in shopList" :key="index">
                            <div class="complete_1_1_place_div"  v-if="item.goods_name">
                                <div class="complete_1_1_place_div_flex">
                                    <div class="complete_1_1_place_div_icon"></div>
                                    <div class="complete_1_1_place_div_title">
                                        <span>{{ item.goods_name }}</span>
                                        <div class="complete_1_1_place_div_title_add"></div>
                                    </div>
                                </div>
                                <div class="complete_1_1_place_div_flex">
                                    <div class="complete_1_1_place_div_flex_1" v-if="item.brand_id">{{ item.brand_id }}</div>
                                    <div class="complete_1_1_place_div_flex_1" v-if="item.category_name">{{item.category_name}}</div>
                                    <div class="complete_1_1_place_div_flex_1" v-if="item.unit">{{ item.unit }}</div>
                                    <div class="complete_1_1_place_div_flex_1" v-if="item.goods_spec">{{item.goods_spec}}</div>
                                    <div class="complete_1_1_place_div_flex_1" v-if="item.country_name">{{ item.country_name }}</div>
                                    <div class="complete_1_1_place_div_flex_money">${{ item.price || 0 }}</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div v-else class="complete_1_1">
                <div class="complete_1_1_3">
                    <div class="complete_1_1_3_title">{{ $t("任务看板") }}</div>
                    <div class="complete_1_1_3_flex">
                        <div class="complete_1_1_3_flex_1">
                            <div class="complete_1_1_3_flex_1_tag">{{ $t("进行中") }}</div>
                            <div class="complete_1_1_3_flex_1_place">
                                <div v-if="taskList1 && taskList1.length">
                                    <div class="complete_1_1_3_flex_1_div" v-for="item in taskList1" :key="item.id">
                                        <div class="complete_1_1_3_flex_1_div_title">{{ item.obname }}</div>
                                        <div v-if="item.remark" class="complete_1_1_3_flex_1_div_desc"><i
                                                class="el-icon-document-copy"></i>{{ $t("任务说明") }}</div>
                                        <div v-if="item.remark" class="complete_1_1_3_flex_1_div_con">{{ item.remark }}</div>
                                        <div class="complete_1_1_3_flex_1_div_time">{{ item.mkdate }}</div>
                                        <div class="complete_1_1_3_flex_1_div_btn">{{ $t("去处理") }}</div>
                                    </div>
                                </div>
                                <div v-else><!-- {{$t("无")}} -->
                                    --</div>
                            </div>
                        </div>
                        <div class="complete_1_1_3_flex_2">
                            <div class="complete_1_1_3_flex_2_tag">{{ $t("已完成") }}</div>
                            <div class="complete_1_1_3_flex_1_place">
                                <div v-if="taskList2 && taskList2.length">
                                    <div class="complete_1_1_3_flex_1_div" v-for="item in taskList2" :key="item.id">
                                        <div class="complete_1_1_3_flex_1_div_title">{{ item.obname }}</div>
                                        <div v-if="item.remark" class="complete_1_1_3_flex_1_div_desc"><i
                                                class="el-icon-document-copy"></i>{{ $t("任务说明") }}</div>
                                        <div v-if="item.remark" class="complete_1_1_3_flex_1_div_con">{{ item.remark }}</div>
                                        <div class="complete_1_1_3_flex_1_div_time">{{ item.mkdate }}</div>
                                        <div class="complete_1_1_3_flex_1_div_btn">{{ $t("去处理") }}</div>
                                    </div>
                                </div>
                                <div v-else><!-- {{$t("无")}} -->
                                    --</div>
                            </div>
                        </div>
                        <div class="complete_1_1_3_flex_3">
                            <div class="complete_1_1_3_flex_3_tag">{{ $t("受阻") }}</div>
                            <div class="complete_1_1_3_flex_1_place">
                                <!-- {{$t("无")}} -->
                                --
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="complete_1_2">
                <div class="complete_1_2_img" v-if="jurisdiction">
                    <div class="complete_1_2_div">
                        <div v-if="form?.company_logo">
                            <el-image :src="$store.state.host + '/entrance' + form.company_logo" class="complete_1_2_div_img"  @click="chooseSet"></el-image>
                        </div>
                        <div v-else class="complete_1_2_div_img" @click="chooseSet"></div>
                        <div>
                            <div class="complete_1_2_div_con">{{ form?.cmcname }}</div>
                            <div class="complete_1_2_div_no">NO.{{ form?.cmccode }}</div>
                            <div>
                                <div class="complete_1_2_div_btn">
                                    <span class="complete_1_2_div_btn_span">{{ form?.orgname }}</span>
                                </div>
                                <!-- <div class="complete_1_2_div_btn2">
                                    <span class="complete_1_2_div_btn_span">AGRICULTURAL PRODUCT</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="complete_1_3_img">
                    <div v-if="form?.photo_pic" class="complete_1_3_img_icon">
                        <el-image :src="$store.state.host + '/entrance' + form.photo_pic" class="complete_1_2_div_img"   @click="chooseSet"
                            style="margin-right: 0;width: 68px;height: 68px;"></el-image>
                    </div>
                    <div v-else class="complete_1_3_img_icon"   @click="chooseSet">
                        <div class="complete_1_3_img_icon_circle" ></div>
                    </div>
                    <div class="complete_1_3_img_name" v-if="form.account">
                        {{ form.account }}
                        <el-image style="width: 14px; height: 14px;margin-left: 16px;"
                            :src="$store.state.host + '/entrance' + value"></el-image>
                    </div>
                    <div v-else style="height:38px"></div>
                    <div class="mt13">
                        <div class="complete_1_3_img_place">{{ form.orgname }}</div>
                    </div>
                    <div class="complete_1_3_img_back">
                        <div class="complete_1_3_img_back_img"></div>
                        <div class="complete_1_3_img_back_con">
                            <div class="complete_1_3_div_con">{{ form.cmcname }}</div>
                            <div class="complete_1_2_div_no">NO.{{ form.cmccode }}</div>
                        </div>
                    </div>
                </div>
                <div class="complete_1_2_url">
                    <div v-if="list&&list.length">
                        <div class="complete_1_2_url_div" v-for="(item, index) in list" :key="index"
                            @click="getUrl(item.url_sign)">
                            <div class="complete_1_2_url_div_icon">
                                <el-image class="complete_1_2_url_img"
                                    :src="$store.state.host + '/entrance' + item.image"></el-image>
                            </div>
                            <div class="complete_1_2_url_div_span">{{ item.sname }}</div>
                        </div>
                    </div>
                    <div v-else class="h78"></div>
                </div>
                <div class="complete_1_2_message">
                    <div>
                        <span class="complete_1_2_message_title">{{ $t("消息通知") }}</span>
                        <div class="complete_1_2_message_icon"></div>
                    </div>
                    <div class="complete_1_2_message_line"></div>
                    <div :class="jurisdiction ? 'complete_1_2_message_auto' : 'complete_1_3_message_auto'">
                        <div v-for="item in messageList" :key="item.iid">
                            <div class="complete_1_2_message_place" v-if="item.sdsc">
                                <div class="complete_1_2_message_place_icon"></div>
                                <div class="complete_1_2_message_flex">
                                    <div class="complete_1_2_message_place_span">
                                        {{ item.sdsc }}
                                        <!-- <div class="complete_1_2_message_place_span_red">3</div> -->
                                    </div>
                                    <div class="complete_1_2_message_place_time">
                                        <span>{{ item.dmake }}</span>
                                        <span class="complete_1_2_message_place_right">{{ item.stitle }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="messageList && messageList.length == 0" style="text-align:center">---</div>
                    </div>
                </div>
                <div class="complete_1_2_message_img">
                    <div class="complete_1_2_message_img_icon"></div>
                    <div class="complete_1_2_message_img_title">{{ $t("智语精灵") }}</div>
                    <div class="complete_1_2_message_img_desc">{{ $t('智能对话') }}</div>
                    <div class="complete_1_2_message_img_btn" @click="openkf">
                        {{ $t("点击开启对话") }}
                        <div class="complete_1_2_message_img_btn_icon"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="menu_index == 1 && showDetail == 0" class="complete_1">
            <div class="complete_1_flex">
                <div class="complete_1_flex_1">
                    <div class="complete_1_flex_1_place_2_bg"></div>
                    <div class="complete_1_flex_1_place">
                        <div v-if="showTop" class="complete_1_flex_1_place_top" >
                            <div class="complete_1_flex_1_place_top_1" >
                                <el-image :src="require('../assets/img/pic.jpg')"
                                    style="width: 160px;height:44px"></el-image>
                                <div style="position: absolute;top: 11px;left: 24px;">
                                    <el-image :src="require('../assets/img/938@2x.png')"
                                        style="width: 16px;height:16px;position: relative;top: 1px;"></el-image>
                                    <span class="complete_1_flex_1_place_top_1_title">{{ $t('货物需求') }}</span>
                                </div>
                            </div>
                            <div style="padding-top:11px;margin-left: 16px;" @click="changeShowTop(0)">
                                <el-image :src="require('../assets/img/939@2x.png')"
                                    style="width: 15px;height:15px;position: relative;top: 1px;"></el-image>
                                <span class="complete_1_flex_1_place_top_1_title2">{{ $t('商品信息') }}</span>
                            </div>
                        </div>
                        <div v-else class="complete_1_flex_1_place_top">
                            <div class="complete_1_flex_1_place_top_1"  @click="changeShowTop(1)">
                                <div style="position: absolute;top: 11px;left: 24px;">
                                    <el-image :src="require('../assets/img/939@2x.png')"
                                        style="width: 16px;height:16px;position: relative;top: 1px;"></el-image>
                                    <span class="complete_1_flex_1_place_top_1_title" style="color:#fff">{{ $t('货物需求')
                                    }}</span>
                                </div>
                            </div>
                            <div style="margin-left: 16px;position: relative;">
                                <el-image :src="require('../assets/img/1pic.jpg')"
                                    style="width: 164px;height:44px;position: relative;left: -36px;"></el-image>

                                <div style="position: absolute;top: 11px;left: 0;">
                                    <el-image :src="require('../assets/img/938@2x.png')"
                                        style="width: 15px;height:15px;position: relative;top: 1px;"></el-image>
                                    <span class="complete_1_flex_1_place_top_1_title2" style="color:#333">{{ $t('商品信息')
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="complete_1_flex_1_place_top_2">
                            <div class="complete_1_flex_1_place_top_2_place">
                                <div class="complete_1_flex_1_place_top_2_place_title">{{ $t('货物分类') }}</div>
                                <el-dropdown>
                                    <span class="el-dropdown-link">
                                        <span
                                            class="complete_1_flex_1_place_top_2_place_title_query">{{ getTypeName(query.type) }}</span>
                                        <i class="el-icon-caret-bottom el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" class="complete_1_flex_1_place_top_2_drop">
                                        <el-dropdown-item v-for="(item, index) in queryList" :key="index">
                                            <div @click="query.type = item.id">{{ item.name }}</div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="complete_1_flex_1_place_top_2_place">
                                <div class="complete_1_flex_1_place_top_2_place_title">{{ $t('品类') }}</div>
                                <el-dropdown>
                                    <span class="el-dropdown-link">
                                        <span
                                            class="complete_1_flex_1_place_top_2_place_title_query">{{ getName1(query.category) }}</span>
                                        <i class="el-icon-caret-bottom el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" class="complete_1_flex_1_place_top_2_drop">
                                        <el-dropdown-item v-for="(item, index) in selList1" :key="index">
                                            <div @click="query.category = item.sid">{{ item.name }}</div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="complete_1_flex_1_place_top_2_place">
                                <div class="complete_1_flex_1_place_top_2_place_title">{{ $t('产地') }}</div>
                                <el-dropdown>
                                    <span class="el-dropdown-link">
                                        <span
                                            class="complete_1_flex_1_place_top_2_place_title_query">{{ getName2(query.origin) }}</span>
                                        <i class="el-icon-caret-bottom el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" class="complete_1_flex_1_place_top_2_drop">
                                        <el-dropdown-item v-for="(item, index) in selList2" :key="index">
                                            <div @click="query.origin = item.sid">{{ item.name }}</div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="complete_1_flex_1_place_top_2_place">
                                <div class="complete_1_flex_1_place_top_2_place_title">{{ $t('货物名称') }}</div>
                                <el-input v-model="query.name" :placeholder="$t('请输入')" size="mini"></el-input>
                            </div>
                            <!-- <div class="complete_1_flex_1_place_top_2_place">
                                <div class="complete_1_flex_1_place_top_2_place_title">{{ $t('关键词') }}</div>
                                <el-input v-model="query.keywords" style="width: 100px;" :placeholder="$t('品种、规格')" size="mini"></el-input>
                            </div> -->
                            <div class="complete_1_flex_1_place_top_2_place_btn" @click="getChange2(1)" v-loading="btn_loading">{{ $t('查询') }}</div>
                        </div>
                    </div>
                    <div class="complete_1_flex_1_place_2">
                        <div class="complete_1_flex_1_place_2_auto">
                            <div v-for="(item, index) in shopList2" :key="index" class="main_list_1_place_1" style="margin-top:0;margin-bottom:16px">
                                <!-- 甄选平台 商品列表 -->
                                <div class="main_list_1_place_1_bg" v-if="!showTop">
                                    <div class="main_list_1_place_flex">
                                        <span class="main_list_1_place_1_title">{{ item.goods_name }}</span>
                                        <div class="main_list_1_place_1_1" v-if="item.goods_category_name">
                                            <el-image :src="require('../assets/img/69@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                            <span class="main_list_1_place_1_1_span">{{ item.goods_category_name }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_2" v-if="item.continent_name">
                                            <span class="main_list_1_place_1_1_span">{{ item.continent_name }}</span>
                                        </div>
                                    </div>
                                    <div class="mtop12" style="min-height:30px">
                                        <div class="main_list_1_place_1_3_title" v-if="item.name_name">
                                            <span>{{ $t('品名') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.name_name }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_3_title">
                                            <span>{{ $t('品种') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.brand_id_name }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_3_title" v-if="item.glevel">
                                            <span>{{ $t('等级') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.glevel }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_3_title" v-if="item.hmethod">
                                            <span>{{ $t('处理方式') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.hmethod }}</span>
                                        </div>
                                        <!-- <div class="main_list_1_place_1_3_right">{{item.price}}</div> -->
                                    </div>
                                    <div>
                                        <div class="main_list_1_place_1_3_title">
                                            <span>{{ $t('产地') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.source_area_name }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_3_title" v-if="item.source_city">
                                            <span>{{ $t('产区') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.source_city }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_3_title" v-if="item.rough_weight">
                                            <span>{{ $t('重量') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.rough_weight }}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- 甄选平台 需求列表 -->
                                <div class="main_list_1_place_1_bg" v-else>
                                    <div class="main_list_1_place_flex">
                                        <span class="main_list_1_place_1_title">{{ item.goods_name }}</span>
                                        <div class="main_list_1_place_1_2" v-if="item.continent_name">
                                            <span class="main_list_1_place_1_1_span">{{ item.continent_name }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_1" v-if="item.d_classify_name">
                                            <el-image :src="require('../assets/img/69@2x.png')"
                                                class="main_list_1_place_1_1_img"></el-image>
                                            <span class="main_list_1_place_1_1_span">{{ item.d_classify_name }}</span>
                                        </div>
                                        <!-- <div class="main_list_1_place_1_1" v-if="item.goods_category">
                                            <el-image :src="require('../assets/img/69@2x.png')" class="main_list_1_place_1_1_img"></el-image>
                                            <span class="main_list_1_place_1_1_span">{{ item.goods_category }}</span>
                                        </div> -->
                                        <div class="main_list_1_place_1_2" v-if="item.goods_category_name">
                                            <span class="main_list_1_place_1_1_span">{{ item.goods_category_name }}</span>
                                        </div>
                                    </div>
                                    <div class="mtop12">
                                        <div class="main_list_1_place_1_3" v-if="item.source_area_name">
                                            <el-image :src="require('../assets/img/70@2x.png')"
                                                class="main_list_1_place_1_1_img"></el-image>
                                            <span class="main_list_1_place_1_1_span">{{ item.source_area_name }}</span>
                                            <span class="main_list_1_place_1_1_span" v-if="item.source_city">
                                                -{{ item.source_city }}
                                            </span>
                                        </div>
                                        
                                        <div class="main_list_1_place_1_3" v-if="item.brand_id_name">
                                            <span class="main_list_1_place_1_1_span">{{item.brand_id_name}}</span>
                                        </div>
                                        <div class="main_list_1_place_1_3" v-if="item.d_numb_des">
                                            <span class="main_list_1_place_1_1_span">{{item.d_numb_des}}</span>
                                        </div>

                                        
                                        
                                        <div class="main_list_1_place_1_3" v-for="(t,i) in item.settings" :key="i">
                                            <span class="main_list_1_place_1_1_span">{{t.setting_name }}&nbsp;{{ item.settings[0].setting_value }}</span>
                                        </div>
                                        <div class="main_list_1_place_1_3_title" v-if="item.goods_spec">
                                            <span>{{ $t('规格') }}</span>
                                            <span class="main_list_1_place_1_3_title_desc">{{ item.goods_spec }}</span>
                                        </div>
                                        <div v-if="item.goods_unit_name" class="main_list_1_place_1_3_title">{{ $t('数量') }}</div>
                                        <div class="main_list_1_place_1_3" v-if="item.goods_unit_name">
                                            <span class="main_list_1_place_1_1_span">{{ item.goods_unit_name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mtop12_line"></div>
                                <div class="main_list_1_place_2_bg">
                                    <el-image :src="$store.state.host + '/entrance' + item.synInfo.logo"
                                        class="main_list_1_place_2_bg_img">
                                        <div slot="error" class="image-slot">
                                                        <el-image :src="require('../assets/img/188.png')"></el-image>
                                                    </div>
                                    </el-image>
                                    <span class="main_list_1_place_2_bg_span">{{ item.synInfo.corporatename }}</span>
                                    <!-- <el-image v-if="item == 1" :src="require('../assets/img/67@2x.png')"
                                        class="main_list_1_place_2_bg_3_img"></el-image>
                                    <el-image v-if="item == 2" :src="require('../assets/img/1130@2x.png')"
                                        class="main_list_1_place_2_bg_3_img"></el-image>
                                    <el-image v-if="item == 3" :src="require('../assets/img/1122@2x.png')"
                                        class="main_list_1_place_2_bg_3_img"></el-image>
                                    <el-image v-if="item == 1" :src="require('../assets/img/66@2x.png')"
                                        class="main_list_1_place_2_bg_4_img"></el-image> -->
                                    <div class="main_list_1_place_2_bg_flex">
                                        <div class="main_list_1_place_2_bg_flex_btn" @click="openDetails(item.sid, item.d_category)">{{ $t('查看详情') }}</div>
                                    </div>
                                </div>
                                <div class="main_list_1_place_1_right">
                                    <el-image :src="require('../assets/img/15@2x.png')"
                                        class="main_list_1_place_1_right_img"></el-image>
                                </div>
                                <div class="main_list_1_place_2_right">
                                    <el-image :src="require('../assets/img/73@2x.png')"
                                        class="main_list_1_place_2_right_img"></el-image>
                                </div>
                                <div style="right:20px;position: absolute;top: 50px;" v-if="!showTop">
                                    <span style="font-weight: 400;font-size: 12px;color: #666666;margin-right: 12px;">{{$t('单价') }}</span>
                                    <span style="font-weight: 800;font-size: 18px;color: #FF9D00;">{{item.tb_currency_symbol}}{{ item.price || 0 }}</span>
                                    <span style="font-weight: 400;font-size: 12px;color: #EDB65D;">/{{  item.goods_unit_name }}</span>
                                </div> 
                            </div>
                        </div>
                        <el-pagination class="complete_1_flex_1_place_2_page" @current-change="getChange2" :current-page="selPage.currPage"
                            layout="prev, pager, next"
                            :total="selPage.total">
                        </el-pagination>
                    </div>
                </div>
                <div style="width: 20px;"></div>
                <div class="complete_1_flex_2">
                    <div v-if="isBuy" class="main_list_3_img1" style="margin-top:0;cursor: pointer;" @click="openDialogVisible2">
                        <div class="main_list_3_img1_title">{{$t("买家需求发布")}}</div>
                    <div class="main_list_3_img1_desc">{{ $t('供应直达') }}</div>
                    </div>
                    <div v-else class="main_list_3_img2" style="margin-top:0;cursor: pointer;" @click="openDialogVisible">
                        <div class="main_list_3_img1_title">{{ $t("卖家商品发布") }}</div>
                        <div class="main_list_3_img1_desc">{{ $t('优质之选') }}</div>
                    </div>
                    <div class="main_list_3_img2_3" @click="showDetail = 2">{{ $t('发布列表') }}</div>
                    <div class="main_list_3_img3" style="height:calc(100% - 307px)"></div>
                </div>
            </div>
        </div>
        <div v-else-if="menu_index == 1 && showDetail == 1" class="complete_other">
            <div class="complete_1_top">
                <!-- <el-input placeholder="" v-model="input" class="complete_1_top_select"
                    prefix-icon="el-icon-search"></el-input> -->
                <div class="complete_1_top_right">

                    <el-badge :value="3" hidden class="item">
                        <div class="complete_1_top_right_message">
                            <div class="complete_1_top_right_message_icon"></div>
                        </div>
                    </el-badge>
                    <el-dropdown style="vertical-align: top;cursor: pointer;">
                        <span class="el-dropdown-link">
                            <el-image v-if="rym_avatar" :src="rym_avatar"
                                style="width: 32px; height: 32px;margin-left: 20px;vertical-align: top;border-radius: 32px;"></el-image>
                            <div class="complete_1_top_right_icon" v-else style="vertical-align: top;"></div>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="chooseSet">{{$t('设置')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="exit">{{ $t('exit') }}</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="complete_1_top_2">
                <span class="complete_1_top_2_1">{{$t("甄选平台")}}</span>
                <span class="complete_1_top_2_2">/</span>
                <span class="complete_1_top_2_3">{{ showTopTitle }}</span>
                <!-- <span class="complete_1_top_2_3">{{ isBuy ? (showTop ? $t('大宗货详情') : $t('跨境商品详情')) : $t('需求详情') }}</span> -->
            </div>
            <div class="complete_2_main" v-if="detailsObj.goods_name">
                <requirement @handBack="showDetail = 0" :obj="detailsObj" :showTop="showTop" :showTopTitle="showTopTitle" :isBuy="!showTop"></requirement>
            </div>
        </div>
        <div v-else-if="menu_index == 1 && showDetail == 2" class="complete_other">
            <div class="complete_1_top">
                <!-- <el-input placeholder="" v-model="input" class="complete_1_top_select"
                    prefix-icon="el-icon-search"></el-input> -->
                <div class="complete_1_top_right">

                    <el-badge :value="3" hidden class="item">
                        <div class="complete_1_top_right_message">
                            <div class="complete_1_top_right_message_icon"></div>
                        </div>
                    </el-badge>
                    <el-dropdown style="vertical-align: top;cursor: pointer;">
                        <span class="el-dropdown-link">
                            <el-image v-if="rym_avatar" :src="rym_avatar"
                                style="width: 32px; height: 32px;margin-left: 20px;vertical-align: top;border-radius: 32px;"></el-image>
                            <div class="complete_1_top_right_icon" v-else style="vertical-align: top;"></div>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="chooseSet">{{$t('设置')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="exit">{{ $t('exit') }}</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="complete_1_top_2">
                <span class="complete_1_top_2_1">{{$t("甄选平台")}}</span>
                <span class="complete_1_top_2_2">/</span>
                <span class="complete_1_top_2_3">{{ $t('发布列表') }}</span>   
            </div>
            <div class="complete_2_main">
                <publish @handBack="showDetail = 0" @goBack="openEdit"></publish>
            </div>
        </div>
        <div v-else-if="menu_index == 1 && showDetail == 3" class="complete_other">
            <div class="complete_1_top">
                <!-- <el-input placeholder="" v-model="input" class="complete_1_top_select"
                    prefix-icon="el-icon-search"></el-input> -->
                <div class="complete_1_top_right">

                    <el-badge :value="3" hidden class="item">
                        <div class="complete_1_top_right_message">
                            <div class="complete_1_top_right_message_icon"></div>
                        </div>
                    </el-badge>
                    <el-dropdown style="vertical-align: top;cursor: pointer;">
                        <span class="el-dropdown-link">
                            <el-image v-if="rym_avatar" :src="rym_avatar"
                                style="width: 32px; height: 32px;margin-left: 20px;vertical-align: top;border-radius: 32px;"></el-image>
                            <div class="complete_1_top_right_icon" v-else style="vertical-align: top;"></div>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="chooseSet">{{$t('设置')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="exit">{{ $t('exit') }}</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="complete_1_top_2">
                <span class="complete_1_top_2_1">{{$t("甄选平台")}}</span>
                <span class="complete_1_top_2_2">/</span>
                <span class="complete_1_top_2_1">{{ isBuy ? $t('买家发布') : $t('卖家商品发布') }}</span>   
                <span class="complete_1_top_2_2">/</span>
                <span class="complete_1_top_2_3">{{ dialogObj.val1 == 1 ? isBuy ? $t('大宗货需求发布') : $t('大宗货物发布') : isBuy ? $t('跨境商品需求发布') : $t('跨境商品发布') }}</span>  
            </div>
            <div class="complete_3_main">
                <goods ref="goods" :countryList="countryList" @handBack="showDetail = 0" :dialogObj="dialogObj" @chooseBack="chooseBack" :key="goodsData"></goods>
            </div>
        </div>
        <div v-else class="complete_other">
            <div class="complete_1_top">
                <!-- <el-input placeholder="" v-model="input" class="complete_1_top_select"
                    prefix-icon="el-icon-search"></el-input> -->
                <div class="complete_1_top_right">

                    <el-badge :value="3" hidden class="item">
                        <div class="complete_1_top_right_message">
                            <div class="complete_1_top_right_message_icon"></div>
                        </div>
                    </el-badge>
                    <!-- <el-image v-if="rym_avatar" :src="rym_avatar"
                        style="width: 32px; height: 32px;margin-left: 20px;vertical-align: top;border-radius: 32px;"></el-image>
                    <div class="complete_1_top_right_icon" v-else style="vertical-align: top;"></div> -->
                    <el-dropdown style="vertical-align: top;cursor: pointer;">
                        <span class="el-dropdown-link">
                            <el-image v-if="rym_avatar" :src="rym_avatar"
                                style="width: 32px; height: 32px;margin-left: 20px;vertical-align: top;border-radius: 32px;"></el-image>
                            <div class="complete_1_top_right_icon" v-else style="vertical-align: top;"></div>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="chooseSet">{{$t('设置')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="token">
                                <div class="btn_top" @click="exit">{{ $t('exit') }}</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="complete_1_main">
                <organization v-if="menu_index == 3"></organization>
                <roleMenu v-if="menu_index == 5 && countryList.length" :countryList="countryList"></roleMenu>
                <userMenu v-if="menu_index == 6 && countryList.length" :countryList="countryList"></userMenu>
                <setMenu v-if="menu_index == 4" @getImg="getImg"></setMenu>
                <agreement v-if="menu_index == 7"></agreement>
            </div>
        </div>


        <el-dialog
        title=""
        z-index="1000"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        width="620px">
        <div v-if="dialogVisibleIndex == 1">
            <div class="show_dialog">
                <span class="show_dialog_title">{{ $t('请选择您所需要发布商品类型') }}</span>
                <el-image @click="dialogVisible = false" :src="require('../assets/img/402@2x.png')" style="width: 14px;height:14px;position: absolute;right: 24px;top: 25px;cursor: pointer;"></el-image>
            </div> 
            <div class="show_dialog_24">
                <div class="show_dialog_flex">
                    <div :class="radio == 1 ? 'show_dialog_flex_1 show_dialog_flex_1_active' : 'show_dialog_flex_1'" style="margin-right:24px">
                        <el-radio v-model="radio" :label="1" style="position: absolute;left: 24px;top: 36px;" size="medium">
                            <span style="font-weight:500;font-size:20px;">{{$t("大宗货物")}}</span>
                        </el-radio>
                        <el-image :src="require('../assets/img/950@2x.png')" style="width: 100px;height:100px;position: absolute;right: 0;top: 0;"></el-image>
                    </div>
                    <div :class="radio == 2 ? 'show_dialog_flex_1 show_dialog_flex_1_active' : 'show_dialog_flex_1'">
                        <el-radio v-model="radio" :label="2" style="position: absolute;left: 24px;top: 36px;" size="medium">
                            <span style="font-weight:500;font-size:20px;">{{$t("跨境商品")}}</span>
                        </el-radio>
                        <el-image :src="require('../assets/img/35@2x1.png')" style="width: 100px;height:100px;position: absolute;right: 0;top: 0;"></el-image>
                    </div>
                </div>
                <el-checkbox v-model="checked"><span style="color:#666666;font-size:14px;font-weight:400">{{ $t('保持该选项') }}</span></el-checkbox>
                <span style="color:#006bfc;font-size:14px;margin-left:12px;cursor: pointer;">{{$t('不再提示')}}</span>
            </div>
            <div class="show_dialog_footer">
                <div class="show_dialog_footer_1" @click="dialogVisible = false">{{ $t('cancel') }}</div> 
                <div class="show_dialog_footer_2" @click="firstNext(radio)">{{ $t('下一步') }}</div> 
            </div> 
        </div>
        <div v-if="dialogVisibleIndex == 5">
            <div class="show_dialog">
                <span class="show_dialog_title">{{ $t('请选择您所需要发布需求类型') }}</span>
                <el-image @click="dialogVisible = false" :src="require('../assets/img/402@2x.png')" style="width: 14px;height:14px;position: absolute;right: 24px;top: 25px;cursor: pointer;"></el-image>
            </div> 
            <div class="show_dialog_24">
                <div class="show_dialog_flex">
                    <div :class="radio == 1 ? 'show_dialog_flex_1 show_dialog_flex_1_active' : 'show_dialog_flex_1'" style="margin-right:24px">
                        <el-radio v-model="radio" :label="1" style="position: absolute;left: 24px;top: 36px;" size="medium">
                            <span style="font-weight:500;font-size:20px;">{{$t("大宗货物")}}</span>
                        </el-radio>
                        <el-image :src="require('../assets/img/950@2x.png')" style="width: 100px;height:100px;position: absolute;right: 0;top: 0;"></el-image>
                    </div>
                    <div :class="radio == 2 ? 'show_dialog_flex_1 show_dialog_flex_1_active' : 'show_dialog_flex_1'">
                        <el-radio v-model="radio" :label="2" style="position: absolute;left: 24px;top: 36px;" size="medium">
                            <span style="font-weight:500;font-size:20px;">{{$t("跨境商品")}}</span>
                        </el-radio>
                        <el-image :src="require('../assets/img/35@2x1.png')" style="width: 100px;height:100px;position: absolute;right: 0;top: 0;"></el-image>
                    </div>
                </div>
                <el-checkbox v-model="checked"><span style="color:#666666;font-size:14px;font-weight:400">{{ $t('保持该选项') }}</span></el-checkbox>
                <span style="color:#006bfc;font-size:14px;margin-left:12px;cursor: pointer;">{{$t('不再提示')}}</span>
            </div>
            <div class="show_dialog_footer">
                <div class="show_dialog_footer_1" @click="dialogVisible = false">{{ $t('cancel') }}</div> 
                <div class="show_dialog_footer_2" @click="pdIndex">{{ $t('下一步') }}</div> 
            </div> 
        </div>
        <div v-if="dialogVisibleIndex == 2">
            <div class="show_dialog">
                <span class="show_dialog_title">{{ $t('请选择您所需要买家类型') }}</span>
                <el-image @click="dialogVisible = false" :src="require('../assets/img/402@2x.png')" style="width: 14px;height:14px;position: absolute;right: 24px;top: 25px;cursor: pointer;"></el-image>
            </div> 
            <div class="show_dialog_24">
                <div class="show_dialog_flex">
                    <div :class="radio2 == 1 ? 'show_dialog_flex_1 show_dialog_flex_1_active' : 'show_dialog_flex_1'" style="margin-right:24px">
                        <el-radio v-model="radio2" :label="1" style="position: absolute;left: 24px;top: 36px;" size="medium">
                            <span style="font-weight:500;font-size:20px;">{{$t("B端商家")}}</span>
                        </el-radio>
                        <el-image :src="require('../assets/img/9950@2x.png')" style="width: 100px;height:100px;position: absolute;right: 0;top: 0;"></el-image>
                    </div>
                    <div :class="radio2 == 2 ? 'show_dialog_flex_1 show_dialog_flex_1_active' : 'show_dialog_flex_1'">
                        <el-radio v-model="radio2" :label="2" style="position: absolute;left: 24px;top: 36px;" size="medium">
                            <span style="font-weight:500;font-size:20px;">{{$t("直面消费者")}}</span>
                        </el-radio>
                        <el-image :src="require('../assets/img/8935@2x.png')" style="width: 100px;height:100px;position: absolute;right: 0;top: 0;"></el-image>
                    </div>
                </div>
                <el-checkbox v-model="checked2"><span style="color:#666666;font-size:14px;font-weight:400">{{ $t('保持该选项') }}</span></el-checkbox>
                <span style="color:#006bfc;font-size:14px;margin-left:12px;cursor: pointer;">{{$t('不再提示')}}</span>
            </div>
            <div class="show_dialog_footer">
                <div class="show_dialog_footer_1" @click="dialogVisible = false">{{ $t('cancel') }}</div>
                <div class="show_dialog_footer_3" @click="dialogVisibleIndex = 1">{{ $t('上一步') }}</div> 
                <div class="show_dialog_footer_2" @click="secNext(radio2)">{{ $t('下一步') }}</div> 
            </div> 
        </div>
        <div v-if="dialogVisibleIndex == 3">
            <div class="show_dialog">
                <span class="show_dialog_title">{{ $t('请选择您所需要入驻的平台') }}</span>
                <el-image @click="dialogVisible = false" :src="require('../assets/img/402@2x.png')" style="width: 14px;height:14px;position: absolute;right: 24px;top: 25px;cursor: pointer;"></el-image>
            </div> 
            <div class="show_dialog_24" style="display: flex;align-items: center;">
                <div v-for="(item, index) in thirdList1" :key="index" :class="dialogVisibleCountry == index ? 'country1_active' : 'country1'" :style="{background: dialogVisibleCountry == index ? `url(${$store.state.host}/entrance${item.check_img}) no-repeat` : `url(${$store.state.host}/entrance${item.img}) no-repeat`}" @click="dialogVisibleCountry = (index)">
                    {{item.name}}
                    <el-image v-if="dialogVisibleCountry == index" :src="require('../assets/img/2@2x.png')" style="width: 10px;height:7px;position: absolute;right: 49%;bottom: -7px;"></el-image>
                </div>
            </div>
            <div class="show_dialog_24" style="padding: 0;padding-bottom:20px" v-if="thirdList1[dialogVisibleCountry] && thirdList1[dialogVisibleCountry].children">
                <div class="show_dialog_24_1" v-for="(item, index) in thirdList1[dialogVisibleCountry].children" :key="index">
                    <div class="show_dialog_24_img1" :style="{background:`url(${$store.state.host}/entrance${item.sys_pimg}) no-repeat`}">
                        <div style="margin-top:20px;margin-left:16px">
                            <el-checkbox v-model="item.check" :disabled="item.disabled"><span style="color:#333;font-size:14px;font-weight:500">{{ item.name }}</span></el-checkbox>
                        </div>
                        <div class="show_dialog_24_span">{{ item.pmsg }}</div> 
                    </div>
                    <div style="display:flex;background:#f4f4f4;border-radius:0px 0px 4px 4px;" v-if="item.sdata">
                        <div style="flex:1" v-for="(t, i) in item.sdata" :key="i">
                            <div style="padding:9px 12px;padding-bottom: 2px;">
                                <span style="color:#e85447;font-weight:700;font-size:18px;">{{ t.value }}</span>
                                <span style="color:#e85447;font-weight:500;font-size:10px;">{{t.vunit}}</span>
                            </div>
                            <div style="color:#666666;font-size:10px;padding-left: 12px;padding-bottom: 9px;">{{ t.title }}</div> 
                        </div>
                    </div> 
                </div> 
            </div> 
            <div class="show_dialog_footer" >
                <div class="show_dialog_footer_1" @click="dialogVisible = false">{{ $t('cancel') }}</div>
                <div class="show_dialog_footer_3" @click="dialogVisibleIndex = 2">{{ $t('上一步') }}</div> 
                <div class="show_dialog_footer_2" @click="thirdNext">{{ $t('下一步') }}</div> 
            </div> 
        </div>
        <div v-if="dialogVisibleIndex == 4">
            <div class="show_dialog">
                <span class="show_dialog_title">{{ $t('协议签署提示') }}</span>
                <el-image @click="dialogVisible = false" :src="require('../assets/img/402@2x.png')" style="width: 14px;height:14px;position: absolute;right: 24px;top: 25px;cursor: pointer;"></el-image>
            </div>
            <div style="text-align:center;padding-top:40px;border-bottom: 1px solid #eaedf4;padding-bottom: 60px;">
                <el-image :src="require('../assets/img/1075@2x.png')" style="width: 48px;height:48px;margin-bottom: 20px;"></el-image>
                <div>{{ $t('检测到您还未签署') }}</div>
            </div> 
            <div class="show_dialog_footer" >
                <div class="show_dialog_footer_1" @click="dialogVisible = false">{{ $t('cancel') }}</div>
                <!-- <div class="show_dialog_footer_3" @click="pdBack">{{ $t('上一步') }}</div>  -->
                <div class="show_dialog_footer_2" @click="signDoc">{{ $t('去签署') }}</div> 
            </div> 
        </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import menuList from '../component/menu.vue'
import organization from './organization.vue'
import roleMenu from './role.vue'
import userMenu from './user.vue'
import requirement from './requirement.vue'
import setMenu from './set.vue'
import publish from './publish.vue'
import agreement from './agreement.vue'
import goods from './goods.vue'
export default {
    name: "complete",
    components: {
        menuList,
        organization,
        roleMenu,
        userMenu,
        setMenu,
        requirement,
        publish,
        agreement,
        goods
    },
    mounted() {
        if (sessionStorage.getItem('rym_token')) {
            this.token = sessionStorage.getItem('rym_token')
            this.user_name = sessionStorage.getItem('rym_user')
            this.init()
        }
        if (sessionStorage.getItem('rym_gwCode') == 'GW0002') {
            this.jurisdiction = true
        } else {
            this.jurisdiction = false
        }
        this.accountList = JSON.parse(sessionStorage.getItem('rym_account')) || []
        this.getImg()
        // this.$i18n.locale = 'en'

        this.isBuy = sessionStorage.getItem('rym_is_buyer') == 'true'

    },
    data() {
        return {
            isBuy: false,
            dialogVisible: false,
            token: '',
            user_name: '',
            country: '',
            country_index: 0,
            countryList: [],
            list: [],
            val: '',
            menu_index: 2,
            input: '',
            jurisdiction: false,
            rym_avatar: '',
            form: {},
            carouselList: [],
            shopList: [],
            messageList: [],
            accountList: [],
            value: '',
            taskList: [],
            taskList2: [],
            taskList1: [],
            showTop: 1,
            showTopTitle: '',
            query: {
                category: '',
                origin: '',
                name: '',
                keywords: '',
                type: 1
            },
            queryList: [
                {
                    id:  1,
                    name: this.$t('大宗货物')
                },
                {
                    id: 2,
                    name: this.$t('跨境商品')
                }
            ],
            showDetail: 0,
            radio: 1,
            checked: false,
            radio2: 1,
            checked2: false,
            dialogVisibleIndex: 1,
            dialogVisibleCountry: 0,
            checked3: false,
            checked4: false,
            checked5: false,
            checked6: false,
            detailsObj: {},
            shopList2: [],
            selList1: [],
            selList2: [],
            dialogObj: {},
            thirdList1: [],
            selPage:{
                total: 0,
                currPage: 1
            },
            goodsData:1,
            btn_loading: false
        }
    },
    methods: {
        chooseSet() {
            this.$refs.menu.handAct(4)
        },
        chooseBack() {
            this.dialogVisible = true
            if (this.isBuy) {
                this.dialogVisibleIndex = 5
            } else {
                this.dialogVisibleIndex = 1
            }
        },
        thirdNext() {
            //判断是否签署协议
            const res2 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"协议是否全部签署","dlgType":"D","dlgCont":"rym.entrance.serv.TBProtocol*202;0;0"}',
                usercode: this.user_name,
                env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey') })
            }))
            res2.then(response => {
                if (response?.data?.data) {
                    if (response.data.data.isAllSigned) {
                        let arr = this.thirdList1[this.dialogVisibleCountry].children
                        let list = arr.filter(item => item.check)
                        if (list.length == 0) return
                        this.dialogVisible = false
                        this.getUrl(list[0].url_sign)
                    } else {
                        this.dialogVisibleIndex = 4
                    }
                }
            })
        },
        firstNext(index) {
            // 大宗货物 直接跳转到发布页面
            if (index == 1) {
                if (this.isBuy) {
                    this.dialogObj = {
                        val1: this.radio
                    }
                } else {
                    this.dialogObj = {
                        val1: this.radio,
                        val2: this.radio2,
                        val3: this.dialogVisibleCountry,
                        val4: [this.checked3, this.checked4, this.checked5, this.checked6]
                    }
                }
                this.dialogVisible = false
                this.showDetail = 3
            } else {
                this.dialogVisibleIndex = 2
            }
        },
        secNext(index) {
            // 跨境商品 B端商家 直接发布
            if (index == 1) {
                if (this.isBuy) {
                    this.dialogObj = {
                        val1: this.radio
                    }
                } else {
                    this.dialogObj = {
                        val1: this.radio,
                        val2: this.radio2,
                        val3: this.dialogVisibleCountry,
                        val4: [this.checked3, this.checked4, this.checked5, this.checked6]
                    }
                }
                this.dialogVisible = false
                this.showDetail = 3
            } else {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"获取当前公司开通的系统","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*213;0;0"}',
                    usercode: this.user_name,
                    env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey'), sysType: 2 })
                }))

                res.then(response => {
                    
                    if (response?.data?.data?.value) {
                        this.thirdList1 = response?.data?.data?.value
                        this.dialogVisibleIndex = 3
                    } else {
                        this.$message.error(response.data.message);
                        if (response?.data?.id == -2) {
                            this.clearUser();
                            this.$router.push('/')
                        }
                    }
                })
                
                
            }
        },
        pdIndex() {
            this.goodsData=this.radio
            if (this.isBuy) {
                this.dialogObj = {
                    val1: this.radio
                }
            } else {
                this.dialogObj = {
                    val1: this.radio,
                    val2: this.radio2,
                    val3: this.dialogVisibleCountry,
                    val4: [this.checked3, this.checked4, this.checked5, this.checked6]
                }
            }
            this.dialogVisible = false
            this.showDetail = 3
        },
        signDoc() {
            this.dialogVisible = false
            this.$refs.menu.handAct(7)
            
            
        },
        openDetails(sid, d_category) {
            this.$refs.menu.handAct(1)
            this.showDetail = 1

            if (d_category == 1) {
                this.showTopTitle = this.$t('大宗货详情')
            } else {
                this.showTopTitle = this.$t('跨境商品详情')
            }
            this.detailsObj = {}

            if (!this.showTop) {
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"商品详情","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*202;0;0"}',
                    usercode: this.user_name,
                    env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey'), sid })
                }))

                res2.then(response => {
                    this.detailsObj = response?.data?.data?.values
                })
            } else {
                const res2 = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"需求详情","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*202;0;0"}',
                    usercode: this.user_name,
                    env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey'), sid })
                }))

                res2.then(response => {
                    this.detailsObj = response?.data?.data?.values
                })
            }

            
        },
        /**
         * 商品/需求编辑
         * @param sid 商品/需求主键
         */
        openEdit(sid){
            this.$refs.menu.handAct(1)
            this.dialogObj.sid = sid;
            this.showDetail = 3
        },
        openDialogVisible() {
            this.dialogVisible = true,
            this.dialogVisibleIndex = 1
        },
        openDialogVisible2() {
            this.dialogVisible = true,
            this.dialogVisibleIndex = 5
        },
        pdBack() {
            if (sessionStorage.getItem('rym_is_buyer') == 'true') {
                this.dialogVisibleIndex = 5
            } else {
                this.dialogVisibleIndex = 1
            }
        },
        getImg() {
            this.rym_avatar = ''
            if (sessionStorage.getItem('rym_avatar')) {
                this.rym_avatar = this.$store.state.host + '/entrance/db_01/' + sessionStorage.getItem('rym_avatar')
            }
        },
        getOptions(data) {
            return {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: {
                    ...data,
                    "snkey": sessionStorage.getItem('rym_snkey')
                },
                url: this.$store.state.host + '/entrance/sysapi',
            }
        },

        init() {
            this.countryList = []
            const res = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取当前公司开通的系统","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*213;0;0"}',
                usercode: this.user_name,
                env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey') })
            }))

            res.then(response => {
                if (response?.data?.data?.value) {
                    this.countryList = JSON.parse(JSON.stringify(response.data.data.value))
                    this.choose(0)
                } else {
                    this.$message.error(response.data.message);
                    if (response?.data?.id == -2) {
                        this.clearUser();
                        this.$router.push('/')
                    }
                }


            })



            const res2 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"社媒账户类型","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*217;0;0"}',
                usercode: this.user_name,
                env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey') })
            }))

            res2.then(response => {
                if (response?.data?.data?.values) {
                    sessionStorage.setItem('rym_account', JSON.stringify(response.data.data.values))
                }
            })

            const res3 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取人员基本信息","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*216;0;0"}',
                usercode: sessionStorage.getItem('rym_user'),
                env: JSON.stringify({ "usercode": "",snkey:sessionStorage.getItem('rym_snkey')  })
            }))

            res3.then(response => {
                this.form = response?.data?.data?.userInfo
                if (this.form?.account_type) {
                    this.accountList.forEach(r => {
                        if (r.sid == this.form.account_type) {
                            this.value = r.image
                        }
                    })
                }

            })

            const res4 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"首页获取轮播图","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*219;0;0"}',
                usercode: this.user_name,
                env: JSON.stringify({ "snkey": sessionStorage.getItem('rym_snkey') })
            }))

            res4.then(response => {
                if (response?.data?.data?.values) {
                    this.carouselList = response?.data?.data?.values
                }
            })
        },

        choose(index) {
            this.country_index = index
            this.country = this.countryList[index]?.id

            const res2 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"获取区域","icon":"iconfont icon-bip-","size":"mini","bIconleft":true,"enable":true,"hasIcon":true,"type":"","hint":"","dlgType":"D","dlgCont":"rym.entrance.serv.SysInfoServ*201;0;0","dlgSname":"","bdcell":"","etap":"","ftap":"","btnPosit":"2","sourcePage":"","visible":false}',
                usercode: this.user_name,
                env: JSON.stringify({ "regionId": this.country, "snkey": sessionStorage.getItem('rym_snkey') })
            }))

            res2.then(response => {
                this.list = response?.data?.data?.value
            })


            // const res = axios(this.getOptions({
            //     apiId: 'dlgclassrun',
            //     dbid: '01',
            //     btn: '{"cmd":"DLG","name":"首页获取商品接口","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*300;0;0"}',
            //     usercode: this.user_name,
            //     env: JSON.stringify({ "regionid": this.country, "page": 1, "pageSize": 100 })
            // }))

            // res.then(response => {
            //     this.shopList = response?.data?.data?.list
            // })
            this.getChange()
            this.getChange2(1)

            const res3 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"首页获取消息接口","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*301;0;0"}',
                usercode: this.user_name,
                env: JSON.stringify({ "regionid": this.country, "page": 1, "pageSize": 100 })
            }))

            res3.then(response => {
                this.messageList = response?.data?.data?.list
            })

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            const res4 = axios(this.getOptions({
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"首页获取任务","dlgType":"D","dlgCont":"rym.entrance.serv.OrganizationInfoServ*302;0;0"}',
                usercode: this.user_name,
                env: JSON.stringify({ "regionid": this.country, "page": 1, "pageSize": 100 })
            }))

            res4.then(response => {
                loading.close();
                this.taskList = response?.data?.data?.list || []
                this.taskList1 = this.taskList.filter(item => item.state1 == 0)
                this.taskList2 = this.taskList.filter(item => item.state1 == 1)
            })
        },

        getChange() {
            if (!this.showTop) {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    usercode: this.user_name,
                    btn: '{"cmd":"DLG","name":"商品列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*200;0;0"}',
                    env: JSON.stringify({
                        snkey: sessionStorage.getItem('rym_snkey'),
                        page: {
                            currPage: 1,
                            pageSize: 10
                        },
                        condition: {
                            d_classify: '',
                            source_area: '',
                            goods_name: this.val
                        }
                    })
                }))
                res.then(res => {
                    if (!res?.data?.data?.values) return
                    this.shopList = res?.data?.data?.values
                })

            } else {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    usercode: this.user_name,
                    btn: '{"cmd":"DLG","name":"需求列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*200;0;0"}',
                    env: JSON.stringify({
                        snkey: sessionStorage.getItem('rym_snkey'),
                        page: {
                            currPage: 1,
                            pageSize: 10
                        },
                        condition: {
                            d_classify: '',
                            source_area: '',
                            goods_name: this.val
                        }
                    })
                }))
                res.then(res => {
                    if (!res?.data?.data?.values) return
                    this.shopList = res?.data?.data?.values
                })
            }
            this.shopList = JSON.parse(JSON.stringify(this.shopList))
            
        },

        changeShowTop(val) {
            this.showTop = val
            this.getSelList1();
            this.getChange2(1)
            this.getChange()
        },
        getChange2(page) {
            if (this.btn_loading) {
                return false
            }
            if(!page)
                page = 1;
            this.selPage.currPage = page;
            this.btn_loading = true
            // if (sessionStorage.getItem('rym_is_buyer') == 'true') {
            if (!this.showTop) {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    usercode: this.user_name,
                    btn: '{"cmd":"DLG","name":"商品列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBGoodServ*200;0;0"}',
                    env: JSON.stringify({
                        snkey: sessionStorage.getItem('rym_snkey'),
                        page: {
                            currPage: this.selPage.currPage,
                            pageSize: 10
                        },
                        condition: {
                            d_classify: this.query.category,
                            source_area: this.query.origin,
                            goods_name: this.query.name,
                            keywords:this.query.keywords,
                            // d_category: this.showTop ? 1 : 2
                            d_category: this.query.type
                        }
                    })
                }))
                res.then(res => {
                    if (!res?.data?.data?.values) return
                    this.shopList2 = res?.data?.data?.values
                    this.selPage.total = res?.data?.data?.page?.total
                    this.btn_loading = false
                })
            } else {
                const res = axios(this.getOptions({
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    usercode: this.user_name,
                    btn: '{"cmd":"DLG","name":"需求列表（全部）","dlgType":"D","dlgCont":"rym.entrance.serv.TBDemandServ*200;0;0"}',
                    env: JSON.stringify({
                        snkey: sessionStorage.getItem('rym_snkey'),
                        page: {
                            currPage: this.selPage.currPage,
                            pageSize: 10
                        },
                        condition: {
                            d_classify: this.query.category,
                            source_area: this.query.origin,
                            goods_name: this.query.name,
                            keywords:this.query.keywords,
                            // d_category: this.showTop ? 1 : 2
                            d_category: this.query.type
                        }
                    })
                }))
                res.then(res => {
                    if (!res?.data?.data?.values) return
                    this.shopList2 = res?.data?.data?.values
                    this.selPage.total = res?.data?.data?.page?.total
                    this.btn_loading = false
                })
            }
        },

        getUrl(url) {
            const res2 = axios(this.getOptions({
                usercode: sessionStorage.getItem('rym_user'),
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"查询Token","icon":"iconfont icon-bip-","size":"mini","bIconleft":true,"enable":true,"hasIcon":true,"type":"","hint":"","dlgType":"D","dlgCont":"inetbas.web.outsys.oauth.BipSysTokenServ*301;0;0","dlgSname":"","bdcell":"","etap":"","ftap":"","btnPosit":"2","sourcePage":"","visible":false}',
            }))

            res2.then(response => {
                let id = "getUrl001";
                let href = url + response.data.data.token + '&locale=' + (this.$i18n.locale == 'en' ? 'en' : 'zh-CN');
                var a = document.createElement('a');
                a.setAttribute('href', href);
                a.setAttribute('target', '_blank');
                a.setAttribute('id', id);
                let a_doc = document.getElementById(id);
                // 防止反复添加
                if (a_doc) {
                    document.body.removeChild(a_doc);
                }
                document.body.appendChild(a);
                a.click();
            })
        },
        openkf() {
            let user_code = sessionStorage.getItem('rym_scm')
            let url = this.$store.state.host + '/entrance/TouristIndex'
            if(user_code){
                url += "?user_code="+user_code
            }
            window.open(url)
        },

        handAct(index) {
            this.showDetail = 0
            this.menu_index = index

            if (index == 1) {
                this.getSelList1();
                const res = axios(this.getOptions({
                    usercode: sessionStorage.getItem('rym_user'),
                    apiId: 'dlgclassrun',
                    dbid: '01',
                    btn: '{"cmd":"DLG","name":"国家信息","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*200;0;0"}',
                    env: JSON.stringify({
                        page: {
                            currPage: 1,
                            pageSize: 1000
                        }
                    })
                }))

                res.then(response => {
                    this.selList2 = [
                        {
                            sid: '',
                            name: '全部'
                        },
                        ...response?.data?.data?.values
                    ]
                }) 
            }
        },
        getSelList1(){
            const res2 = axios(this.getOptions({
                usercode: sessionStorage.getItem('rym_user'),
                apiId: 'dlgclassrun',
                dbid: '01',
                btn: '{"cmd":"DLG","name":"商品分类","dlgType":"D","dlgCont":"rym.entrance.serv.TBSettings*203;0;0"}',
                env: JSON.stringify({
                    page: {
                        currPage: 1,
                        pageSize: 1000
                    },
                    condition: {
                        d_category: this.showTop?"1":"2"
                    }
                })
            }))

            res2.then(response => {
                this.selList1 = [
                    {
                        sid: '',
                        name: '全部'
                    },
                    ...response?.data?.data?.values
                ]
            }) 
        },
        getName1(sid) {
            let arr = this.selList1.filter(item => item.sid == sid)
            if (arr.length) {
                return arr[0].name
            } else {
                return '全部'
            }

        },
        getTypeName(sid) {
            let arr = this.queryList.filter(item => item.id == sid)
            if (arr.length) {
                return arr[0].name
            } else {
                return '全部'
            }
        },
        getName2(sid) {
            let arr = this.selList2.filter(item => item.sid == sid)
            if (arr.length) {
                return arr[0].name
            } else {
                return '全部'
            }

        },
        exit() {
            this.$confirm(this.$t('log_out'), this.$t('please_confirm'), {
                confirmButtonText: this.$t('determine'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.clearUser();
                this.$router.push('/')
            }).catch(() => { });
        },
        clearUser(){
            this.token = ''
            sessionStorage.removeItem("rym_token");
            sessionStorage.removeItem("rym_snkey");
            sessionStorage.removeItem("rym_user");
            sessionStorage.removeItem("rym_index");
            sessionStorage.removeItem("rym_scm");
        }
    }
}
</script>


<style>@import url(../assets/complete.css);</style>
<style>
.el-dropdown-menu{
    max-height: 200px;
    overflow: auto;
}
</style>
<style scoped>
.show_dialog{
    height:64px;
    background:#f3f5f9;
    border-radius:2px 2px 0px 0px;
    line-height: 64px;
    font-weight:500;
    color:#333333;
    font-size:16px;
    position: relative;
}
.show_dialog_title{
    padding-left: 24px;
}
.show_dialog_24{
    padding: 24px;
    border-bottom: 1px solid #eaedf4;
}
.show_dialog_flex{
    display: flex;
    margin-bottom: 20px;
}
.show_dialog_flex_1{
    flex: 1;
    position: relative;
    height:100px;
    border:1px solid;
    border-color:#dee4ed;
    border-radius:4px;
    line-height: 100px;
    background: rgba(243, 245, 249, 1);
}
.show_dialog_flex_1_active{
    border:2px solid;
    border-color:#006bfc;
}
.show_dialog_footer{
    padding: 14px 0;
    text-align: center;
}
.show_dialog_footer_1{
    width:98px;
    height:32px;
    background:#ffffff;
    border:1px solid;
    border-color:#dddfe7;
    border-radius:3px;
    text-align: center;
    line-height: 32px;
    font-weight:500;
    color:#333333;
    font-size:14px;
    display: inline-block;
    cursor: pointer;
}
.show_dialog_footer_2{
    margin-left: 16px;
    width:98px;
    height:32px;
    background:#006bfc;
    border-radius:3px;
    box-shadow:0px 3px 14px rgba(167, 167, 167, 0.14);
    text-align: center;
    line-height: 32px;
    font-weight:500;
    color:#ffffff;
    font-size:14px;
    display: inline-block;
    cursor: pointer;
}
.show_dialog_footer_3{
    margin-left: 16px;
    width: 98px;
    height: 32px;
    background: #ffffff;
    border: 1px solid;
    border-color: #006bfc;
    border-radius: 3px;
    box-shadow: 0px 3px 14px rgba(167, 167, 167, 0.14);
    text-align: center;
    line-height: 32px;
    display: inline-block;
    cursor: pointer;
    font-weight:500;
    color:#006bfc;
    font-size:14px;
}

.country1{
  /* background: url('../assets/img/942.png') no-repeat; */
  background-size: 100% 100%!important;
  height: 40px;
  margin: 10px 0;
  width: 168px;
  padding-left: 14px;
  line-height: 40px;
  font-weight:500;
  color:#333333;
  font-size:14px;
  cursor: pointer;
  margin-right: 14px;
}
.country1_active{
  /* background: url('../assets/img/937.png') no-repeat; */
  background-size: 100% 100%!important;
  height: 40px;
  width: 168px;
  margin: 10px 0;
  padding-left: 14px;
  line-height: 40px;
  font-weight:500;
  color:rgba(255, 255, 255, 1);
  font-size:14px;
  cursor: pointer;
  position: relative;
  margin-right: 14px;
}
.country2{
  background: url('../assets/img/941.png') no-repeat;
  background-size: 100% 100%;
  height: 40px;
  margin: 10px 0;
  padding-left: 14px;
  line-height: 40px;
  width: 100%;
  font-weight:500;
  color:#333333;
  font-size:14px;
  cursor: pointer;
}
.country2_active{
  background: url('../assets/img/938.png') no-repeat;
  background-size: 100% 100%;
  height: 40px;
  margin: 10px 0;
  padding-left: 14px;
  width: 100%;
  line-height: 40px;
  font-weight:500;
  color:rgba(255, 255, 255, 1);
  font-size:14px;
  cursor: pointer;
  position: relative;
}
.country3{
  background: url('../assets/img/940.png') no-repeat;
  background-size: 100% 100%;
  height: 40px;
  width: 100%;
  margin: 10px 0;
  padding-left: 14px;
  line-height: 40px;
  font-weight:500;
  color:#333333;
  font-size:14px;
  cursor: pointer;
}
.country3_active{
  background: url('../assets/img/939.png') no-repeat;
  background-size: 100% 100%;
  height: 40px;
  margin: 10px 0;
  padding-left: 14px;
  line-height: 40px;
  font-weight:500;
  color:rgba(255, 255, 255, 1);
  font-size:14px;
  width: 100%;
  cursor: pointer;
  position: relative;
}
.show_dialog_24_1{
    display: inline-block;
    margin: 20px;
    width: 274px;
    margin-right: 12px;
    background: #ddd;
    vertical-align: top;
    margin-bottom: 0;
}
.show_dialog_24_img1{
  /* background: url('../assets/img/11950@2x.png') no-repeat; */
  background-size: 100% 100%!important;
  height: 142px;
}
.show_dialog_24_img3{
  background: url('../assets/img/1155@2x.png') no-repeat;
  background-size: 100% 100%;
  height: 142px;
}
.show_dialog_24_img4{
  background: url('../assets/img/1159@2x.png') no-repeat;
  background-size: 100% 100%;
  height: 142px;
}
.show_dialog_24_2{
    display: inline-block;
    margin: 20px;
    width: 274px;
    margin-left: 12px;
    background: #1d1f27;
    vertical-align: top;
    margin-bottom: 0;
}
.show_dialog_24_img2{
  background: url('../assets/img/1157@2x.png') no-repeat;
  background-size: 100% 100%;
  height: 142px;
}
.show_dialog_24_span{
    width: 132px;
    height: 58px;
    line-height: 20px;
    color: #666666;
    font-size: 12px;
    margin-top: 20px;
    margin-left: 16px;
}
</style>