<template>
  <div id="app">
    <router-view :key="key"/>
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    key() {
      return this.$route.fullPath
    }
  }
}
</script>
<style>
body{
  margin: 0;
  padding: 0;
}
.el-scrollbar__wrap {
  margin-bottom: -4px!important;
}
/* .el-button--primary{
  background: linear-gradient(to bottom, #419EC8, #66B160)!important;
  border: none!important;
} */
</style>
